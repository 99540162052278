<script>
export default {
  name: "AppList",
  data() {
    return {};
  },
  props: {
    customStyle: {
      type: String,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<template>
  <v-list :class="'app-list ' + customClass" three-line :style="customStyle">
    <slot></slot>
  </v-list>
</template>

<style lang="scss">
.app-list {
  @media screen and (min-width: 768px) {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;

    :last-child {
      margin-right: auto;
    }
  }
}
</style>
