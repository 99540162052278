<script>
export default {
  name: "AppListItem",
  data() {
    return {};
  },
  props: {
    customStyle: { type: String },
  },
};
</script>

<template>
  <v-list-item :style="customStyle + ' overflow: visible;'">
    <v-list-item-content style="padding: 0px; overflow: visible">
      <slot />
    </v-list-item-content>
  </v-list-item>
</template>

<style lang="scss">
.v-list-item {
  padding: 0px !important;
}
</style>
