const validationRules = {
  name: (v) =>
    (!!v && v.length >= 6) || "O nome precisa conter pelo menos 6 caracteres.",
  name1: (v) =>
    (!!v && v.length >= 6) || "O nome precisa conter pelo menos 6 caracteres.",
  name2: (v) =>
    v.length === 0 ||
    v.length >= 6 ||
    "O nome precisa conter pelo menos 6 caracteres.",
  cpf1: (v) => v.length === 14 || "CPF inválido.",
  cpf2: (v) => v.length === 0 || v.length === 14 || "CPF inválido.",
  phone: (v) => v.length === 0 || v.length === 15 || "Número inválido.",
  phone1: (v) => v.length === 15 || "Número inválido.",
  phone2: (v) => v.length === 0 || v.length === 15 || "Número inválido.",
  email2: (v) =>
    v.length === 0 || /.+@.+\..+/.test(v) || "Email está inválido.",
  email1: (v) => /.+@.+\..+/.test(v) || "Email está inválido.",

  emailAuth: (v) =>
    !!v || /.+@.+\..+/.test(v) || "Email está inválido ou vazio.",
  password: (v) =>
    !!v || v.length >= 8 || "A senha precisa ter 8 ou mais caracteres.",
  confirmPassword: (v) =>
    !!v || v.length >= 8 || "A senha precisa ter 8 ou mais caracteres.",
  birthday: (v) => v.length === 10 || "Data inválida.",
  date: (v) => v.length === 0 || v.length === 10 || "Data inválida.",

  cep: (v) => v.length === 9 || "CEP inválido.",
  street: (v) =>
    (!!v && v.length >= 4) || "Logradouro precisa ter pelo menos 4 caracteres.",
  neighborhood: (v) =>
    (!!v && v.length >= 4) || "Bairro precisa ter pelo menos 4 caracteres.",
  city: (v) =>
    (!!v && v.length >= 4) || "Cidade precisa ter pelo menos 4 caracteres.",
  state: (v) =>
    (!!v && v.length >= 2) || "Estado precisa ter pelo menos 2 caracteres.",

  number: (v) => v.length > 0 || "Número inválido.",
  rg: (v) => v.length === 0 || v.length >= 10 || "CEP inválido.",
  nextGrade: (v) => !!v || "Série inválida.",
  period: (v) => v.length > 0 || "Turno inválido.",
  originSchool: (v) => v.length > 1 || "Escola inválida",
  identity: (file) =>
    !!file ||
    file?.size < 2000000 ||
    "A imagem é obrigatória e deve ser menor que 2 MB!",
  identityFront: (file) =>
    !!file ||
    file?.size < 2000000 ||
    "A imagem é obrigatória e deve ser menor que 2 MB!",
  identityBack: (file) =>
    !!file ||
    file?.size < 2000000 ||
    "A imagem é obrigatória e deve ser menor que 2 MB!",
  grade: (file) =>
    !!file ||
    file?.size < 2000000 ||
    "A imagem é obrigatória e deve ser menor que 2 MB!",
};

export default validationRules;
