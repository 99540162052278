<script>
import AppButton from "@/components/atoms/AppButton.vue";
export default {
  name: "ContestSegmentFilter",
  components: {
    AppButton,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    current: {
      type: String,
      default: "",
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload.value);
    },
  },
};
</script>

<template>
  <span style="display: flex; flex-direction: row; gap: 4px">
    <app-button
      v-for="item in items"
      v-bind:key="item.value"
      type="new-return"
      :name="item.value"
      :text="item.text"
      width="100%"
      class="contest-segment-button"
      :customClass="
        current === item.value ? 'contest-segment-button--active ' : ''
      "
      @click="emit(item)"
    >
    </app-button>
  </span>
</template>

<style lang="scss">
.contest-segment-button {
  &:first-child {
    button {
      border-bottom-left-radius: 20px !important;
    }
  }
  &:last-child {
    button {
      border-bottom-right-radius: 20px !important;
    }
  }
}
</style>
