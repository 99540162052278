<script>
import AppLoadingTemplate from "./components/templates/AppLoadingTemplate.vue";

export default {
  name: "LiteratoForms",
  components: {
    AppLoadingTemplate,
  },
  computed: {
    loading: function () {
      return this.$store.getters.loading;
    },
    isMobile() {
      return window.innerWidth < 1024;
    },
  },
  mounted() {
    this.updateHeight();
    window.addEventListener("resize", this.updateHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateHeight);
  },
  updated() {
    window.addEventListener("resize", () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  methods: {
    updateHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
};
</script>

<template>
  <v-app>
    <v-main>
      <v-container id="app-container" fill-height fluid ma-0 pa-0>
        <app-loading-template v-if="loading" />
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss">
:root {
  --primary-flat: #47a57a;
  --primary-gradient: radial-gradient(
    95.65% 850.19% at 3.57% 9.78%,
    #47a57a 0%,
    #52cd94 100%
  );
  --purple-gradient: radial-gradient(
    95.65% 850.19% at 3.57% 9.78%,
    #b12dcc 0%,
    #4e2a99 100%
  );
  --disabled-gradient: radial-gradient(
    95.65% 850.19% at 3.57% 9.78%,
    #ded0e2 0%,
    #95919c 100%
  );
  --wine-gradient: radial-gradient(
    95.65% 850.19% at 3.57% 9.78%,
    #c30179 0%,
    #5c0048 100%
  );
  --primary-gradient2: radial-gradient(
    95.65% 850.19% at 3.57% 9.78%,
    #ee9242 0%,
    #b24534 100%
  );
  --green-gradient: radial-gradient(
    95.65% 850.19% at 3.57% 9.78%,
    #60cd5d 0%,
    #7bcfd1 100%
  );
  --blue-flat: #1976d2;
  --blue-menus: #607ecc;
  --blue-link: #1a83ff;
  --blue-button-border: #94accf;

  --white-opacity: rgba(255, 255, 255, 0.8);

  --gray-lighter: #eaeaea;
  --gray-light: rgba(148, 172, 207, 0.3);
  --gray-medium: #909090;
  --gray-medium2: #797979;
  --gray-dark: #565656;
  --gray-darker: #323232;
  --green-flat: #759a58;
  --white: #ffffff;
  --box-shadow: 0px 4px 34px rgba(33, 76, 158, 0.25);
  --text-shadow: 0px 0px 20px #0c8aff;
  --font-family: "Nunito";
  --font-smaller: 14px;
  --font-small: 15px;
  --font-medium: 16px;
  --font-big: 16px;
  --font-bigger: 18px;
  --font-title: 20px;
  --font-title-big: 22px;
  --font-title-bigger: 32px;
}

* {
  box-sizing: border-box !important;
  cursor: default;
  user-select: none;
  line-height: 100%;
}
input {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

input {
  cursor: text;
}

p {
  margin: 0 !important;
}

html {
  overflow-y: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  min-height: -webkit-fill-available;
  width: 100vw !important;
  font-family: "Nunito";
  // max-width: 100%;
  overflow-x: hidden !important;

  max-height: calc(var(--vh, 1vh) * 100) !important;
}

// body {
//   overflow-y: scroll !important;
// }

.container {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  min-height: -webkit-fill-available;
  width: 100vw !important;
  font-family: "Nunito";
  // max-width: 100%;
  overflow-x: hidden !important;
}

@media screen and (max-height: 600px) and (max-width: 500px) {
  .v-application,
  .v-main {
    max-height: calc(var(--vh, 1vh) * 100) !important;
  }
  .container.container--fluid .scrollable {
    max-height: calc((var(--vh, 1vh) * 100) - 56px) !important;
  }

  .container {
    overflow-y: scroll !important;
    max-width: 100vw !important;
  }
}

.container.fill-height {
  @media screen and (max-height: 600px) and (max-width: 500px) {
    max-height: calc(var(--vh, 1vh) * 100) !important;
  }
  align-items: center !important;
  justify-content: center !important;
  // background-image: url("assets/bg-mobile.png");
  background-size: cover;
  background-color: #199a52;
  @media screen and (min-width: 768px) {
    // background-image: url("assets/bg-desktop.png");
  }
}
.container.container--fluid {
  overflow-y: visible !important;
  align-items: flex-start !important;
}

.screen-container {
  min-height: 100%;
  min-width: 100%;
  background-color: var(--white) !important;
}

.page-title {
  width: 100%;
  margin: 16px auto;
  text-align: center;
  font-size: var(--font-title-bigger);
  font-weight: 600;
}

@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./assets/fonts/Nunito/Nunito-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./assets/fonts/Quicksand/Quicksand-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
</style>
