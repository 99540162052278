<script>
import AppInput from "@/components/atoms/AppInput.vue";
import validationRules from "@/constants/form/validation-rules";

export default {
  name: "AddressForm",
  components: {
    AppInput,
  },
  data() {
    return {
      form: {},
      valid: true,
      validationRules,
    };
  },
  mounted() {
    for (var input in this.inputList) {
      if (input != undefined) {
        this.form[input] = this.inputList[input].value
          ? this.inputList[input].value
          : "";
      }
    }

    this.emit({ name: "change", formName: this.formName, value: this.form });
  },
  props: {
    formName: { type: String },
    submitAction: { type: Boolean },
    validateAction: { type: Boolean },
    /* still to make validation object */
    inputList: { type: Object },
  },
  watch: {
    inputList: {
      handler(val) {
        Object.keys(val).forEach((key) => {
          this.form[key] = val[key].value;
        });
        /* this.updateValue({ name: name, value: value }); */
      },
      deep: true,
      immediate: true,
    },
    submitAction() {
      this.submit();
    },
    validateAction(val) {
      if (val == true) {
        this.validate();
      }
    },
  },
  methods: {
    handleChange({ name, value }) {
      this.updateValue({ name: name, value: value });
      this.emit({
        name: "change",
        formName: this.formName,
        value: this.form,
      });
    },
    validate() {
      this.$emit("validate", this.$refs["form"].validate());
    },
    updateValue({ name, value }) {
      this.form[name] = value;
    },
    submit() {
      if (this.$refs["form"].validate()) {
        this.emit({
          name: "submit",
          formName: this.formName,
          value: this.form,
        });
      }
    },
    emit({ name, formName, value }) {
      this.$emit(name, { name: formName, fields: value });
    },
  },
};
</script>

<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="align-center justify-center"
    style="width: 100%; max-width: 448px"
  >
    <div
      v-if="form['cep'].length < 9 && form['city'].length == 0"
      class="form__inputs"
    >
      <app-input
        v-bind:key="inputList['cep'].name"
        :value="inputList['cep'].value"
        :disabled="inputList['cep'].disabled"
        :required="inputList['cep'].required"
        :keyboardType="inputList['cep'].keyboardType"
        :label="inputList['cep'].label"
        :name="inputList['cep'].name"
        :mask="inputList['cep'].mask"
        :inputType="inputList['cep'].inputType"
        :dateType="inputList['cep'].dateType"
        :appendIcon="inputList['cep'].appendIcon"
        :items="inputList['cep'].items"
        :validationRules="
          validationRules[inputList['cep'].name]
            ? [validationRules[inputList['cep'].name]]
            : []
        "
        @change="handleChange"
        @enter="submit"
      />
    </div>
    <div v-else class="form__inputs">
      <app-input
        v-for="input in inputList"
        v-bind:key="input.name"
        :value="input.value"
        :disabled="input.disabled"
        :required="input.required"
        :keyboardType="input.keyboardType"
        :label="input.label"
        :name="input.name"
        :mask="input.mask"
        :inputType="input.inputType"
        :dateType="input.dateType"
        :appendIcon="input.appendIcon"
        :items="input.items"
        :validationRules="
          validationRules[input.name] ? [validationRules[input.name]] : []
        "
        @change="handleChange"
        @enter="submit"
      />
    </div>
    <slot />
  </v-form>
</template>
