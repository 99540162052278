const getters = {
  contestList: (state) => {
    return state.contestList;
  },
  currentSorting: (state) => {
    return state.order.value;
  },
  currentFiltering: (state) => {
    return state.filter.value;
  },
};

export default getters;
