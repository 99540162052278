const segmentList = [
  {
    text: "Infantil e 1º ano",
    value: "infantil",
  },
  {
    text: "Fundamental - Anos Iniciais",
    value: "fundamental1",
  },
  {
    text: "Fundamental - Anos Finais",
    value: "fundamental2",
  },
  {
    text: "Médio",
    value: "medio",
  },
];

export default segmentList;
