import candidateOrder from "@/constants/candidate-order";
import initialState from "./state";

const mutations = {
  orderContest: (state, payload) => {
    state.order = candidateOrder.find((e) => e.value === payload);
  },
  setContestList: (state, payload) => {
    state.contestList = payload;
  },
  RESET: (state) => {
    Object.assign(state, initialState);
  },
};

export default mutations;
