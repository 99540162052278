<script>
export default {
  name: "TheInnerToolbar",
  methods: {
    emit() {
      this.$emit("click");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  props: {
    title: { type: String },
    subtitle: { type: String },
    icon: { type: String },
    disabled: { type: Boolean, default: false },
    isForm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <v-toolbar id="inner-toolbar" flat>
    <v-btn @click="cancel" icon>
      <v-icon>{{ isForm ? "mdi-close" : "mdi-arrow-left" }}</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <v-toolbar-title
      style="text-align: center; display: flex; flex-direction: column"
    >
      <small style="font-size: var(--font-small)">{{ subtitle }}</small>
      <h5>{{ title }}</h5>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      v-if="icon != undefined && icon.length > 0"
      @click="emit"
      icon
      :disabled="disabled"
      style="border: 0 !important"
    >
      <v-icon color="#607ecc" style="text-shadow: 0px 0px 20px #0085ff">{{
        icon
      }}</v-icon>
    </v-btn>
    <div v-else style="width: 40px"></div>
  </v-toolbar>

  <!-- // TODO: Add here another toolbar option? Or 2 versions + flow of control?  -->
</template>

<style lang="scss">
#inner-toolbar {
  border-radius: 20px;
  box-shadow: var(--box-shadow) !important;
  height: 56px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  max-height: 56px;

  @media screen and (min-width: 1200px) {
    width: 60%;
    margin: 0 auto;
    max-height: 64px;
    height: 64px;
    max-width: 448px;
    margin: 0 auto;
    margin-top: 32px;
  }
}
</style>
