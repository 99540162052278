<script>
import LoginTemplate from "@/components/templates/admin/LoginTemplate.vue";

export default {
  components: { LoginTemplate },
  name: "LoginPage",
  data: () => ({
    valid: false,
    showPassword: false,
  }),
  methods: {
    authenticate(form) {
      /* transfer validation to other place */

      this.$store.dispatch("auth/login", form);
    },
    goToRecover() {
      this.$router.push("/admin/recuperar-senha");
    },
  },
};
</script>

<template>
  <login-template @LogIn="authenticate" @Recover="goToRecover" />
</template>
