import initialState from "./state";

const mutations = {
  setAuth: (state, payload) => {
    state.currentAuth = payload;
  },
  setSegment: (state, payload) => {
    state.currentSegment = payload;
  },
  RESET: (state) => {
    Object.assign(state, initialState);
  },
};

export default mutations;
