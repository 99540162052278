<script>
import SelectionHomeTemplate from "@/components/templates/SelectionHomeTemplate.vue";

export default {
  components: { SelectionHomeTemplate },
  name: "SelectionHomePage",
  mounted() {
    this.$store.dispatch("public/reset", { root: true });
  },
  methods: {
    async click(payload) {
      this.$router.push("/selecao/" + payload);
    },
  },
};
</script>

<template>
  <selection-home-template @click="click" />
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
