const turns = [
  {
    value: "morning",
    text: "Matutino",
  },
  {
    value: "afternoon",
    text: "Vespertino",
  },
];

export default turns;
