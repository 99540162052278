import { render, staticRenderFns } from "./CandidateListPage.vue?vue&type=template&id=97a37770&"
import script from "./CandidateListPage.vue?vue&type=script&lang=js&"
export * from "./CandidateListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports