const schoolLevelInfantil = [
  {
    value: "maternal1",
    text: "Maternal 1",
  },
  {
    value: "maternal2",
    text: "Maternal 2",
  },
  {
    value: "infant1",
    text: "Infantil 1",
  },
  {
    value: "infant2",
    text: "Infantil 2",
  },
  {
    value: "year1",
    text: "1º Ano",
  },
];

export default schoolLevelInfantil;
