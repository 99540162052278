<script>
import HomeTemplate from "@/components/templates/HomeTemplate.vue";

export default {
  components: { HomeTemplate },
  name: "HomePage",
  mounted() {
    this.$store.dispatch("public/reset", { root: true });
  },
  methods: {
    async click(payload) {
      if (payload != "selecao2025") {
        this.$router.push("/" + payload);
      } else {
        window.location.assign("https://selecao2025.literato.net");
      }
    },
  },
};
</script>

<template>
  <home-template @click="click" />
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
