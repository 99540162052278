const getters = {
  loading: (state) => {
    return state.loading;
  },
  error: (state) => {
    return state.error;
  },
};

export default getters;
