const schoolLevelMedio = [
  {
    value: "medio1",
    text: "1ª Série",
  },
  {
    value: "medio2",
    text: "2ª Série",
  },
  {
    value: "medio3",
    text: "3ª Série",
  },
];

export default schoolLevelMedio;
