import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

Vuetify.config.silent = true;

export default new Vuetify({
  lang: {
    langs: { pt },
    current: "pt",
  },
});
