<script>
import AppButton from "@/components/atoms/AppButton.vue";
import AppListItem from "@/components/atoms/AppListItem.vue";
import CandidateSchoolInfo from "@/components/molecules/CandidateSchoolInfo.vue";
import format from "@/helpers/format";

export default {
  components: {
    AppButton,
    AppListItem,
    CandidateSchoolInfo,
  },
  name: "CandidateListCard",
  data() {
    return {
      buttonSize: 60,
    };
  },
  props: {
    id: { type: String },
    name: {
      type: String,
      default: "",
    },
    createdAt: {
      type: Object,
      default: () => {},
    },
    period: {
      type: String,
    },
    nextGrade: {
      type: String,
      default: "",
    },
    originSchool: {
      type: String,
      default: "",
    },
    birthday: {
      type: String,
      default: "",
    },
    pdfUrl: {
      type: String,
    },
  },
  methods: {
    emit(event) {
      this.$emit(event, this.id);
    },
  },
  mounted() {
    this.buttonSize = this.$refs.container.offsetHeight / 2;
  },
  computed: {
    formatedName: function () {
      let formatedName = format.name(this.name);
      if (formatedName.length > 28) {
        return formatedName.substring(0, 27) + "...";
      } else {
        return formatedName;
      }
    },
  },
};
</script>

<template>
  <app-list-item>
    <span ref="container" class="candidate-card-container">
      <span class="candidate-card">
        <span class="candidate-info">
          <div class="candidate-name">
            <p>{{ formatedName }}</p>
          </div>
          <candidate-school-info
            :birthday="birthday"
            :period="period"
            :createdAt="createdAt"
            :nextGrade="nextGrade"
            :originSchool="originSchool"
          />
        </span>
      </span>
      <span class="candidate-card__buttons">
        <app-button
          type="icon"
          @click="emit('clickProfile')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 20px 5px 5px"
          color="var(--white)"
          iconColor="green"
          icon="mdi-account-circle-outline"
        />
        <app-button
          v-if="pdfUrl === undefined"
          type="icon"
          @click="emit('clickPdf')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 5px 20px 5px"
          color="var(--white)"
          iconColor="blue"
          icon="mdi-file-pdf-box"
          :disabled="false"
        />
        <app-button
          v-else-if="pdfUrl.length === 0"
          type="icon"
          @click="emit('clickPdf')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 5px 20px 5px"
          color="var(--white)"
          iconColor="blue"
          icon="mdi-progress-clock"
          :disabled="true"
        />
        <app-button
          v-else
          type="icon"
          @click="emit('clickPdf')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 5px 20px 5px"
          color="var(--white)"
          iconColor="blue"
          icon="mdi-file-download"
          :disabled="false"
        />
      </span>
    </span>
  </app-list-item>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-bigger);
}

.candidate-card-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  gap: 4px;
  flex-direction: row;
  justify-content: space-around;
}

.candidate-card {
  border-radius: 20px 5px 5px 20px;
  box-sizing: border-box;
  background-color: var(--white);
  width: 100%;
  min-width: 286px;

  display: flex;
  flex-direction: row;
  background: var(--white-opacity);
  box-shadow: var(--box-shadow);
}

.candidate-name {
  text-align: left;
}

.candidate-card__buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.candidate-info {
  padding: 12px 10px 12px 10px;
  width: 100%;

  display: flex;
  font-size: var(--font-smaller);
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  justify-content: flex-start;
  @media screen and (min-width: 400px) {
    padding: 12px 12px 12px 12px;
  }
  @media screen and (min-width: 420px) {
    padding: 16px 24px 16px 24px;
  }
}

.candidate-avatar {
  display: flex;
  flex-direction: column;
}
</style>
