<script>
import format from "@/helpers/format";

export default {
  name: "CandidateShowDocument",
  data() {
    return {};
  },
  props: {
    id: { type: String },
    name: {
      type: String,
      default: "",
    },
    link: {
      type: String,
    },
  },
  computed: {
    formatedBirthday: function () {
      return format.date(this.birthday);
    },
    formatedName: function () {
      return format.name(this.name);
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <span class="candidate-show-document-item-info">
    <div class="candidate-show-document-item-name">
      <p>{{ name }}</p>
    </div>
    <div class="candidate-show-document-item-buttons">
      <v-btn
        class="ma-2"
        text
        icon
        style="
          padding: 12px 0;
          text-shadow: 0px 0px 20px #534d3c;
          color: #534d3c;
        "
        @click="emit({ type: 'view', data: link })"
      >
        <v-icon>mdi-eye-outline</v-icon>
      </v-btn>
      <!-- <v-btn
        class="ma-2"
        text
        icon
        style="
          padding: 12px 0;
          text-shadow: 0px 0px 20px #0085ff;
          color: #607ecc;
        "
        @click="emit({ type: 'download', data: link })"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn> -->
    </div>

    <!-- generate a dialog to confirm de call / whatss / whatever -->
  </span>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-small);
}
.candidate-show-document-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 36px;
  padding-right: 16px;
}
.candidate-show-document-item-name {
  text-align: left;
  padding: 8px 0;
}

.candidate-show-document-item-info {
  width: 100%;

  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
