<script>
import bottomMenu from "@/constants/bottom-menu";

export default {
  name: "TheBottomNavigation",
  data: () => ({
    options: bottomMenu,
    placeholder: true,
    activeTab: "selection",
  }),
  props: {
    currentTab: { type: String },
  },
  methods: {
    change(event) {
      this.$emit("change", event);
    },
  },
};
</script>

<template>
  <v-bottom-navigation
    :value="currentTab"
    color="var(--green-flat)"
    :fixed="placeholder"
    :mandatory="placeholder"
    shift
  >
    <v-btn
      :value="option.value"
      v-for="option in options"
      v-bind:key="option.value"
      style="overflow: visible"
      @click="change(option.value)"
    >
      <span style="font-size: var(--font-small); font-weight: 700">{{
        option.text
      }}</span>

      <v-icon size="32" class="icon-effect">{{ option.icon }}</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<style lang="scss">
.v-bottom-navigation {
  background: var(--white-opacity);
  border: 2px solid var(--white-opacity);

  .v-btn--active {
    .icon-effect,
    .v-icon {
      text-shadow: var(--text-shadow) !important;
    }
  }

  .v-btn:before {
    background-color: var(--white);
  }

  .v-btn__content {
    overflow: visible;

    background-color: var(--white);
  }
}
</style>
