import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import state from "./state";
import modules from "./modules";
import indexPlugin from "../plugins";

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "selecao.literato.net",
  modules: ["public"],
});

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules,
  state,
  getters,
  mutations,
  actions,
  plugins: [indexPlugin, vuexLocal.plugin],
  strict: debug,
});

export default store;
