const bottomMenu = [
  {
    value: "selection",
    icon: "mdi-account-multiple-check-outline",
    text: "Seleção",
  },
  {
    value: "contest",
    icon: "mdi-podium-gold",
    text: "Concurso",
  },
];

export default bottomMenu;
