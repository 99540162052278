<script>
import { mask } from "vue-the-mask";
import format from "@/helpers/format";
import AppButton from "@/components/atoms/AppButton.vue";
/* import { Money } from "v-money"; */

export default {
  name: "AppInput",
  components: { AppButton /* Money */ },
  directives: { mask /* money: VMoney */ },
  data() {
    return {
      hidden: true,
      dialog: false,
      file: null,
      money: {
        locale: "pt-BR",
        prefix: "R$ ",
        length: 11,
        suffix: "",
        precision: 2,
        masked: false,
      },
      activePicker: null,
    };
  },
  created() {
    this.hidden = this.inputType === "password" ? true : false;
  },
  props: {
    label: { type: String },
    name: { type: String },
    icon: { type: String },
    value: {
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    keyboardType: {
      type: String,
      default: "text",
    },
    inputType: {
      type: String,
      default: "text",
    },
    mask: {
      type: Array,
      default: () => [],
    },
    dateType: {
      type: String,
      default: "date",
    },
    dateMin: {
      type: String,
      default: "",
    },
    dateMax: {
      type: String,
      default: "",
    },
    dateActivePicker: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    customStyle: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleFile() {
      this.$refs.file.$refs.input.click();
    },
    appendClick() {
      this.hidden = !this.hidden;
    },
    emit(eventName) {
      this.$emit(eventName, { value: this.localValue, name: this.name });
    },
    confirmDate() {
      this.dialog = false;
      /* this.emit("change"); */
    },
    fileChange(payload) {
      this.$emit("change", { name: this.name, value: payload });
    },
  },
  computed: {
    formatedDateFull: {
      get() {
        if (this.dateType === "month") {
          return this.localValue ? format.reference(this.localValue) : "";
        }
        return this.localValue ? format.date(this.localValue) : "";
      },
      set(val) {
        if (val.length === 10) {
          this.$emit("change", {
            name: this.name,
            value: format.revertDate(val),
          });
        }
      },
    },
    localValue: {
      get() {
        return this.value;
      },
      set(val) {
        if (this.inputType === "email") {
          this.$emit("change", { name: this.name, value: val.toLowerCase() });
        } else {
          this.$emit("change", { name: this.name, value: val });
        }
      },
    },
  },
  watch: {
    dialog(val) {
      val &&
        this.dateActivePicker === "YEAR" &&
        setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
// fazer o upload no estado ou na page // // como gerenciar o arquivo depois?

<template>
  <span class="button-container">
    <v-file-input
      v-if="inputType === 'file'"
      v-model="file"
      ref="file"
      accept="image/png, image/jpeg, image/bmp"
      placeholder="Selecione o arquivo"
      prepend-icon=""
      append-icon="mdi-camera"
      @click:append="handleFile"
      @change="fileChange"
      rounded
      :label="label"
      outlined
      :rules="validationRules"
    ></v-file-input>

    <v-text-field
      v-if="inputType === 'text' && mask.length === 0"
      v-model="localValue"
      :label="label"
      :required="required"
      :inputmode="keyboardType"
      :disabled="disabled"
      v-on:keyup.enter="emit('enter')"
      hide-details
      outlined
      :type="keyboardType"
      style="border-radius: 20px"
      autocomplete="off"
      :rules="validationRules"
    />
    <vuetify-money
      v-if="inputType === 'money'"
      v-model="localValue"
      :label="label"
      :disabled="disabled"
      :outlined="true"
      hide-details
      valueWhenIsEmpty="0,00"
      inputmode="numeric"
      :options="money"
      :properties="{
        style: 'border-radius: 20px;',
      }"
      :rules="validationRules"
    />
    <v-text-field
      v-if="inputType === 'text' && mask.length > 0"
      v-model="localValue"
      :label="label"
      :required="required"
      :inputmode="keyboardType"
      :disabled="disabled"
      v-mask="[...mask]"
      hide-details
      outlined
      type="text"
      style="border-radius: 20px"
      autocomplete="off"
      :rules="validationRules"
    />
    <v-text-field
      v-if="inputType === 'password'"
      v-model="localValue"
      :label="label"
      :required="required"
      :inputmode="keyboardType"
      :disabled="disabled"
      hide-details
      outlined
      style="border-radius: 20px"
      v-on:keyup.enter="emit('enter')"
      :append-icon="hidden ? 'mdi-eye-off' : 'mdi-eye'"
      :type="hidden ? 'password' : 'text'"
      @click:append="appendClick"
      :rules="validationRules"
    />
    <v-text-field
      v-if="inputType === 'search'"
      name="name"
      v-model="localValue"
      text
      solo
      hide-details
      rounded
      :label="label"
      :required="required"
      :inputmode="keyboardType"
      :type="inputType"
      :prepend-inner-icon="icon"
      v-on:keyup.enter="emit('enter')"
      :class="'app-button-search ' + customClass"
      style="
        width: 100%;
        margin: 0 auto;
        font-size: var(--font-big);
        font-weight: 700;
      "
    >
    </v-text-field>

    <span v-if="inputType === 'date' && dateType === 'date'">
      <v-text-field
        v-model="formatedDateFull"
        :label="label"
        :required="required"
        inputmode="numeric"
        outlined
        hide-details
        v-mask="'##/##/####'"
        style="border-radius: 20px"
        append-icon="mdi-calendar"
        @click:append="dialog = true"
        :rules="validationRules"
      ></v-text-field>
      <v-dialog v-model="dialog" width="290px">
        <v-date-picker
          v-model="localValue"
          :ref="name"
          :disabled="disabled"
          :type="dateType"
          :max="dateMax"
          :min="dateMin"
          :active-picker.sync="activePicker"
        >
          <v-spacer></v-spacer>
          <!-- <v-btn name="cancel" text color="primary" @click="dialog = false">
            Cancelar
          </v-btn> -->
          <v-btn name="confirm" text color="primary" @click="confirmDate">
            Fechar
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </span>

    <span v-if="inputType === 'date' && dateType === 'month'">
      <v-text-field
        v-model="formatedDateFull"
        :label="label"
        :required="required"
        outlined
        readonly
        hide-details
        inputmode="numeric"
        style="border-radius: 20px"
        append-icon="mdi-calendar"
        @click="dialog = true"
        @click:append="dialog = true"
      ></v-text-field>
      <v-dialog v-model="dialog" width="290px">
        <v-date-picker
          v-model="localValue"
          :disabled="disabled"
          :type="dateType"
          :max="dateMax"
          :min="dateMin"
        >
          <v-spacer></v-spacer>
          <!-- <v-btn name="cancel" text color="primary" @click="dialog = false">
            Cancelar
          </v-btn> -->
          <v-btn name="confirm" text color="primary" @click="confirmDate">
            Fechar
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </span>

    <v-dialog v-if="inputType === 'iconDate'" v-model="dialog" width="290px">
      <template v-slot:activator="{ on }">
        <app-button
          icon="mdi-calendar-month"
          :customStyle="customStyle"
          type="icon"
          iconColor="rgba(0, 0, 0, 0.87)"
          size="46px"
          :on="on"
        >
          <slot></slot>
        </app-button>
      </template>
      <v-date-picker
        v-model="localValue"
        :disabled="disabled"
        :type="dateType"
        :max="dateMax"
        :min="dateMin"
      >
        <v-spacer></v-spacer>
        <!-- <v-btn name="cancel" text color="primary" @click="dialog = false">
          Cancelar
        </v-btn> -->
        <v-btn name="confirm" text color="primary" @click="confirmDate">
          Fechar
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-dialog v-if="inputType === 'button-date'" v-model="dialog" width="290px">
      <template v-slot:activator="{ on }">
        <app-button
          name="change-month"
          text="Alterar Mês"
          type="new-return"
          icon="mdi-calendar-month"
          customStyle="background-color: var(--blue-flat) !important; letter-spacing: 0;"
          :on="on"
          width="calc(100% - 12px)"
          @click="dialog = true"
          customContainerStyle="transform: translateX(16px);"
        />
      </template>
      <v-date-picker
        v-model="localValue"
        :disabled="disabled"
        :type="dateType"
        :max="dateMax"
        :min="dateMin"
      >
        <v-spacer></v-spacer>
        <!-- <v-btn name="cancel" text color="primary" @click="dialog = false">
          Cancelar
        </v-btn> -->

        <v-btn name="confirm" text color="primary" @click="confirmDate">
          Fechar
        </v-btn>
      </v-date-picker>
    </v-dialog>

    <v-select
      v-if="inputType === 'select'"
      v-model="localValue"
      :label="label"
      :required="required"
      :disabled="disabled"
      :items="items"
      hide-details
      outlined
      :rules="validationRules"
      style="border-radius: 20px"
    >
    </v-select>

    <v-switch
      v-if="inputType === 'switch'"
      v-model="localValue"
      :label="label"
      :required="required"
      :type="keyboardType"
      :disabled="disabled"
      inputmode="none"
      :color="localValue == true ? 'var(--blue-flat)' : '#757575'"
      hide-details
      outlined
      style="border-radius: 20px"
    ></v-switch>
  </span>
</template>

<style lang="scss">
// App File Input
.v-application--is-ltr .v-text-field--outlined.v-text-field--rounded legend {
  margin-left: 0;
  width: 120%;
}

// END APP File Input
.theme--light.v-list.v-select-list {
  background-color: var(--white);
}

.theme--light.v-input {
  color: rgba(117, 117, 117, 0.25) !important;
}

.theme--light.v-stepper {
  background-color: transparent !important;
}

.button-container {
  width: 100%;
}

// .v-file-input {
//   flex-direction: row-reverse;
// }

// .v-file-input {
//   .v-text-field--outlined .v-label--active {
//     transform: translateY(-16px) translateX(4px) scale(0.8) !important;
//   }
// }

.v-application--is-ltr .v-input__prepend-outer {
  margin-top: 10px !important;
  margin-left: 9px !important;
  margin-right: 0 !important;
}

.app-button-search {
  .v-input__slot {
    border-radius: 20px 8px 8px 20px !important;

    height: 36px !important;
    @media screen and (min-width: 1200px) and (max-width: 1499px) {
      height: 36px !important;
      font-size: var(--font-big);
    }
    @media screen and (min-width: 1500px) {
      height: 40px !important;
      font-size: var(--font-big);
    }
  }
}

.border-20 {
  .v-input__slot {
    border-radius: 20px 20px 8px 8px !important;
  }
}

.v-text-field.v-text-field--solo .v-input__prepend-inner {
  margin-top: 0 !important;
}

.v-select-list {
  .v-list-item__content {
    padding: 0 16px !important;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  background-color: var(--white-opacity);
}

.v-menu__content {
  border-radius: 20px !important;
}

.v-select__selection--comma,
.v-text-field--outlined .v-text-field__prefix {
  transform: translateY(3px);
}

.v-text-field__prefix {
  color: rgba(0, 0, 0, 0.6);
}

// Select Input Customization
.v-input--selection-controls.v-input {
  margin-top: 0px !important;
  transform: translateY(-6px);
}

.v-input--switch .v-input--selection-controls__input {
  width: 44px !important;
}

.v-input--switch__track {
  width: 44px !important;
  height: 20px !important;
  border-radius: 20px !important;
  background-color: var(--white) !important;
  transform: translateY(-2px);
  border: 1px solid;
}

.theme--light.v-input--switch .v-input--switch__thumb {
  height: 14px;
  width: 14px;
  transform: translate(6px, 4px);
  box-shadow: none !important;
}

.v-input--switch:not(.v-input--switch--flat):not(.v-input--switch--inset)
  .v-input--switch__thumb {
  color: var(--gray-medium2);
}

.v-application--is-ltr
  .v-input--switch.v-input--is-dirty
  .v-input--switch__thumb {
  transform: translate(28px, 4px) !important;
}

.v-application--is-ltr
  .v-input--switch.v-input--is-dirty
  .v-input--selection-controls__ripple,
.v-application--is-ltr .v-input--switch .v-input--selection-controls__ripple {
  display: none !important;
}

// END Select Input Customization

.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
  height: 40px;
}
.v-text-field input {
  padding: 8px 4px !important;
}

.v-text-field--outlined .v-label {
  top: 12px !important;
  font-size: var(--font-medium);
  margin-left: 4px !important;
}
.v-text-field--outlined {
  font-size: var(--font-medium);
  font-weight: 600;
}
.v-text-field--outlined .v-label--active {
  transform: translateY(-16px) translateX(-4px) scale(0.77) !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 10px !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: var(--box-shadow) !important;
}
.center-input-text input {
  text-align: center;
  transform: translateX(-3%);
}

.v-text-field.center-input-text .v-label {
  left: 50% !important;
  transform: translateX(-58%);
  &.v-label--active {
    transform: translateY(-18px) scale(0.75) translateX(-50%);
  }
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 12px !important;
}

.v-btn--fixed.v-btn--bottom {
  bottom: calc(56px + 16px);
}

.toolbar-container {
  padding: 40px 5vw;
}
</style>
