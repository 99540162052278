const userOrder = [
  {
    value: "name",
    text: "A-Z",
  },
  {
    value: "createdAt",
    text: "Ordem de Cadastro",
  },
  {
    value: "nextGrade",
    text: "Série em 2025",
  },
];

export default userOrder;
