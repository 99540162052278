<script>
import AppCard from "@/components/atoms/AppCard.vue";
import CandidateShowDocumentItem from "@/components/molecules/CandidateShowDocumentItem.vue";
import format from "@/helpers/format";

export default {
  name: "CandidateShowDocument",
  components: {
    AppCard,
    CandidateShowDocumentItem,
  },
  data() {
    return {};
  },
  props: {
    id: { type: String },
    documents: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    formatedBirthday: function () {
      return format.date(this.birthday);
    },
    formatedName: function () {
      return format.name(this.name);
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <app-card
    customStyle="padding: 16px; width: 100%;background-color: var(--white-opacity); "
  >
    <span class="candidate-show-info">
      <div class="candidate-show-name">
        <p>Documentos</p>
      </div>

      <candidate-show-document-item
        v-if="documents.identityUrl != undefined"
        :link="documents.identityUrl"
        name="Identidade"
        @click="emit"
      />
      <candidate-show-document-item
        v-if="documents.identityFrontUrl != undefined"
        :link="documents.identityFrontUrl"
        name="Identidade Frente"
        @click="emit"
      />
      <div
        v-if="documents.identityBackUrl != undefined"
        class="divider"
        style="margin-top: 0"
      />
      <candidate-show-document-item
        v-if="documents.identityBackUrl != undefined"
        :link="documents.identityBackUrl"
        name="Identidade Costas"
        @click="emit"
      />
      <div
        v-if="documents.gradeUrl != undefined"
        class="divider"
        style="margin-top: 0"
      />
      <candidate-show-document-item
        v-if="documents.gradeUrl != undefined"
        :link="documents.gradeUrl"
        name="Boletim"
        @click="emit"
      />
      <div
        v-if="documents.enemUrl != undefined"
        class="divider"
        style="margin-top: 0"
      />
      <candidate-show-document-item
        v-if="documents.enemUrl != undefined"
        :link="documents.enemUrl"
        name="Boletim ENEM"
        @click="emit"
      />
      <!-- generate a dialog to confirm de call / whatss / whatever -->
    </span>
  </app-card>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-bigger);
}
.candidate-show-name {
  text-align: left;
}

.candidate-show-info {
  margin: 0.5vw 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
