<script>
import AppCard from "@/components/atoms/AppCard.vue";
import CandidateSchoolInfo from "@/components/molecules/CandidateSchoolInfo.vue";
import turns from "@/constants/turns";
import format from "@/helpers/format";

export default {
  name: "CandidateShowCard",
  components: {
    AppCard,
    CandidateSchoolInfo,
  },
  data() {
    return {};
  },
  props: {
    id: { type: String },
    name: {
      type: String,
      default: "",
    },
    contact: {
      type: Object,
      default: () => {},
    },
    createdAt: {
      type: Object,
      default: () => {},
    },
    period: {
      type: String,
    },
    birthday: {
      type: String,
      default: "",
    },
    nextGrade: {
      type: String,
    },
    originSchool: {
      type: String,
    },
  },
  computed: {
    formatedBirthday: function () {
      return format.date(this.birthday);
    },
    formatedName: function () {
      return format.name(this.name);
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
    getPeriodTitle(period) {
      if (period && period.length > 0) {
        return turns.find((periodItem) => periodItem.value === period).text;
      } else {
        return "";
      }
    },
  },
};
</script>

<template>
  <app-card
    class=""
    customStyle="padding: 16px; width: 100%;background-color: var(--white-opacity);"
  >
    <span class="candidate-show-info">
      <div class="candidate-show-name">
        <p>{{ formatedName }}</p>
      </div>
      <div class="divider" style="margin-top: 0" />

      <candidate-school-info
        :birthday="birthday"
        :period="getPeriodTitle(period)"
        :createdAt="createdAt"
        :nextGrade="nextGrade"
        :originSchool="originSchool"
      />
      <!-- generate a dialog to confirm de call / whatss / whatever -->
    </span>
  </app-card>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-bigger);
}
.candidate-show-name {
  text-align: left;
}

.candidate-show-info {
  margin: 0.5vw 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
