import Vue from "vue";
import VueRouter from "vue-router";
import firebase from "firebase/compat/app";
import store from "../store";

import HomePage from "@/components/pages/HomePage.vue";
import SelectionHomePage from "@/components/pages/SelectionHomePage.vue";
import SelectionCreatePage from "@/components/pages/SelectionCreatePage.vue";
// import ContestTermsPage from "@/components/pages/ContestTermsPage.vue";
// import ContestCreatePage from "@/components/pages/ContestCreatePage.vue";
import AdminRouter from "@/components/templates/admin/AdminRouter.vue";
import LoginPage from "@/components/pages/admin/LoginPage.vue";
import CandidateListPage from "@/components/pages/admin/CandidateListPage.vue";
import CandidateShowPage from "@/components/pages/admin/CandidateShowPage.vue";
import NotFoundPage from "@/components/pages/NotFoundPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    component: NotFoundPage,
  },
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/selecao/",
    name: "SelectionHomePage",
    component: SelectionHomePage,
  },
  {
    path: "/selecao/:segment",
    name: "SelectionCreate",
    props: true,
    component: SelectionCreatePage,
  },
  // {
  //   path: "/concurso",
  //   name: "ContestTerms",
  //   props: true,
  //   component: ContestTermsPage,
  // },
  // {
  //   path: "/concurso/novo-candidato",
  //   name: "ContestCreate",
  //   props: true,
  //   component: ContestCreatePage,
  // },
  {
    path: "/obrigado",
    name: "CandidateSuccess",
    meta: { requiresAuth: false },
    component: () => import("@/components/pages/CandidateSuccessPage.vue"),
  },
  {
    path: "/erro",
    name: "CandidateError",
    meta: { requiresAuth: false },
    component: () => import("@/components/pages/CandidateErrorPage.vue"),
  },
  {
    path: "/admin",
    component: AdminRouter,
    children: [
      {
        path: "login",
        name: "Login",
        component: LoginPage,
      },
      {
        path: "",
        name: "CandidateList",
        meta: { requiresAuth: true },
        component: CandidateListPage,
      },
      {
        path: "selecao",
        meta: { requiresAuth: true },
        redirect: () => "/admin",
      },
      {
        path: "perfil-candidato/:id",
        props: true,
        name: "Perfil do Candidato",
        meta: { requiresAuth: true },
        component: CandidateShowPage,
      },
      {
        path: "*",
        redirect: "404",
      },
    ],
  },
  {
    path: "*",
    redirect: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if (store.state.auth.currentAuth === undefined) {
          store.dispatch("auth/setAuth", Object.freeze(user.multiFactor.user));
        }
        return next();
      } else {
        store.dispatch("logout");
      }
    });
  } else {
    return next();
  }
});

export default router;
