<script>
import AppButton from "@/components/atoms/AppButton.vue";
import AppListItem from "@/components/atoms/AppListItem.vue";
import ContestSchoolInfo from "@/components/molecules/ContestSchoolInfo.vue";
import format from "@/helpers/format";

export default {
  components: {
    AppButton,
    AppListItem,
    ContestSchoolInfo,
  },
  name: "ContestListCard",
  data() {
    return {
      buttonSize: 60,
    };
  },
  props: {
    id: { type: String },
    name: {
      type: String,
      default: "",
    },
    createdAt: {
      type: Object,
      default: () => {},
    },
    originSchool: {
      type: String,
      default: "",
    },
    enemUrl: {
      type: String || null,
    },
    gradeUrl: {
      type: String || null,
    },
    identityFrontUrl: {
      type: String || null,
    },
    identityBackUrl: {
      type: String || null,
    },
    pdfUrl: {
      type: String,
    },
  },
  methods: {
    emit(event) {
      this.$emit(event, this.id);
    },
  },
  mounted() {
    this.buttonSize = (this.$refs.container.offsetHeight - 4) / 2;
  },
  computed: {
    formatedName: function () {
      let formatedName = format.name(this.name);
      if (formatedName.length > 28) {
        return formatedName.substring(0, 27) + "...";
      } else {
        return formatedName;
      }
    },
  },
};
</script>

<template>
  <app-list-item>
    <span ref="container" class="contest-card-container">
      <span class="contest-card-left-side">
        <span class="contest-card">
          <span class="contest-info">
            <div class="contest-name">
              <p>{{ formatedName }}</p>
            </div>
            <contest-school-info :originSchool="originSchool" />
          </span>
        </span>
        <span class="contest-card-doc__buttons">
          <app-button
            type="icon"
            @click="emit('clickEnem')"
            width="77px"
            height="56px"
            borderRadius="5px 5px 5px 20px"
            color="var(--white)"
            iconColor="blue"
            icon="mdi-podium-gold"
            :disabled="enemUrl === null"
          />
          <app-button
            type="icon"
            @click="emit('clickGrade')"
            width="77px"
            height="56px"
            borderRadius="5px 5px 5px 5px"
            color="var(--white)"
            iconColor="blue"
            icon="mdi-school-outline"
            :disabled="gradeUrl === null"
          />
          <app-button
            type="icon"
            @click="emit('clickIdentityFront')"
            width="77px"
            height="56px"
            borderRadius="5px 5px 5px 5px"
            color="var(--white)"
            iconColor="blue"
            icon="mdi-smart-card-outline"
            :disabled="identityFrontUrl === null"
          />
          <app-button
            type="icon"
            @click="emit('clickIdentityBack')"
            width="77px"
            height="56px"
            borderRadius="5px 5px 5px 5px"
            color="var(--white)"
            iconColor="blue"
            icon="mdi-id-card"
            :disabled="identityBackUrl === null"
          />
        </span>
      </span>
      <span class="contest-card__buttons">
        <app-button
          type="icon"
          @click="emit('clickProfile')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 20px 5px 5px"
          color="var(--white)"
          iconColor="green"
          icon="mdi-account-circle-outline"
        />
        <app-button
          v-if="pdfUrl === undefined"
          type="icon"
          @click="emit('clickPdf')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 5px 20px 5px"
          color="var(--white)"
          iconColor="blue"
          icon="mdi-file-pdf-box"
          :disabled="false"
        />
        <app-button
          v-else-if="pdfUrl.length === 0"
          type="icon"
          @click="emit('clickPdf')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 5px 20px 5px"
          color="var(--white)"
          iconColor="blue"
          icon="mdi-progress-clock"
          :disabled="true"
        />
        <app-button
          v-else
          type="icon"
          @click="emit('clickPdf')"
          width="60px"
          :height="buttonSize + 'px'"
          borderRadius="5px 5px 20px 5px"
          color="var(--white)"
          iconColor="blue"
          icon="mdi-file-download"
          :disabled="false"
        />
      </span>
    </span>
  </app-list-item>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-bigger);
}

.contest-card-container {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  gap: 4px;
  flex-direction: row;
  justify-content: space-around;
}

.contest-card {
  border-radius: 20px 5px 5px 5px;
  min-height: 82px;
  box-sizing: border-box;
  background-color: var(--white);
  width: 100%;
  min-width: 286px;

  display: flex;
  flex-direction: row;
  background: var(--white-opacity);
  box-shadow: var(--box-shadow);
}

.contest-card-left-side {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contest-card-doc__buttons {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.contest-name {
  text-align: left;
}

.contest-card__buttons {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
}

.contest-info {
  padding: 12px 10px 12px 10px;
  width: 100%;

  display: flex;
  font-size: var(--font-smaller);
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  justify-content: flex-start;
  @media screen and (min-width: 400px) {
    padding: 12px 12px 12px 12px;
  }
  @media screen and (min-width: 420px) {
    padding: 16px 24px 16px 24px;
  }
}

.contest-avatar {
  display: flex;
  flex-direction: column;
}
</style>
