<script>
export default {
  name: "AppImage",
  data() {
    return {};
  },
  props: {
    customStyle: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "logo",
    },
    height: {
      type: String,
      default: "10%'",
    },
    width: {
      type: String,
      default: "100%'",
    },
  },
  methods: {
    getImgUrl(pet) {
      var images = require.context("../../assets/", false, /\.svg$/);
      if (pet.length > 0) {
        return images("./" + pet + ".svg");
      }
      return images("./logo.svg");
    },
  },
};
</script>

<template>
  <img
    class="image"
    :src="getImgUrl(image)"
    :style="
      customStyle +
      ' height: ' +
      height +
      '; max-height: 20%; width: ' +
      width +
      ';'
    "
    contain
  />
</template>

<style lang="scss">
.image {
  margin: 0 auto;
  max-width: 100%;
}
</style>
