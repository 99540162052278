<script>
import { mapGetters } from "vuex";
import CandidateListTemplate from "@/components/templates/admin/CandidateListTemplate.vue";
import { convertSelectionCandidateToPdf } from "@/functions/selection/convertSelectionCandidateToPdf";

export default {
  name: "CandidateListPage",
  data() {
    return {
      placeholder: true,
      activeTab: "selection",
    };
  },
  components: {
    CandidateListTemplate,
  },
  updated() {
    if (this.selectionList === undefined && this.currentSegment != undefined) {
      this.$store.dispatch("selection/list", { root: true });
    }
  },
  mounted() {
    if (this.selectionList === undefined && this.currentSegment != undefined) {
      this.$store.dispatch("selection/list", { root: true });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    goToCandidateShow(memberId) {
      window.open(
        "https://selecao.literato.net/admin/perfil-candidato/" + memberId,
        "_blank"
      );
      // this.$router.push("/admin/perfil-candidato/" + memberId);
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    changeFilter(payload) {
      this.$store.dispatch("user/order", payload);
    },
    changeTab(payload) {
      this.activeTab = payload;
    },
    async clickPdf(payload) {
      let candidate = {};
      if (this.activeTab === "selection") {
        candidate = this.selectionList.find(
          (candidateItem) => candidateItem.id === payload
        );
      } else {
        candidate = this.contestList.find(
          (candidateItem) => candidateItem.id === payload
        );
      }
      if (candidate.pdfUrl === undefined) {
        let newPayload = {
          id: payload,
          candidate: convertSelectionCandidateToPdf(candidate),
        };
        this.$store.dispatch("loading", true);
        await this.$store.dispatch("selection/update", newPayload);
      } else if (candidate.pdfUrl.length > 0) {
        window.open(
          candidate.pdfUrl,
          "_blank" // <- This is what makes it open in a new window.
        );
      }
    },
    clickEnem(payload) {
      let candidate = this.contestList.find(
        (candidateItem) => candidateItem.id === payload
      );
      window.open(candidate.document.enemUrl, "_blank");
    },
    clickGrade(payload) {
      let candidate = this.contestList.find(
        (candidateItem) => candidateItem.id === payload
      );
      window.open(candidate.document.gradeUrl, "_blank");
    },
    clickIdentityFront(payload) {
      let candidate = this.contestList.find(
        (candidateItem) => candidateItem.id === payload
      );
      window.open(candidate.document.identityFrontUrl, "_blank");
    },
    clickIdentityBack(payload) {
      let candidate = this.contestList.find(
        (candidateItem) => candidateItem.id === payload
      );
      window.open(candidate.document.identityBackUrl, "_blank");
    },
  },
  computed: {
    ...mapGetters({
      selectionList: "selection/selectionList",
      contestList: "contest/contestList",
    }),
    currentSegment() {
      return this.$store.state.auth.currentSegment;
    },
  },
};
</script>

<template>
  <span>
    <candidate-list-template
      v-if="selectionList != undefined"
      :candidateList="selectionList"
      :contestList="contestList"
      :currentTab="activeTab"
      @clickLogout="logout"
      @changeFilter="changeFilter"
      @clickProfile="goToCandidateShow"
      @changeTab="changeTab"
      @clickPdf="clickPdf"
      @clickEnem="clickEnem"
      @clickGrade="clickGrade"
      @clickIdentityFront="clickIdentityFront"
      @clickIdentityBack="clickIdentityBack"
    />
  </span>
</template>
