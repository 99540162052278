import authService from "@/services/authService";

const actions = {
  reset({ commit }) {
    commit("RESET");
  },
  async signup({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const auth = await authService.signup({ ...payload });

      commit("setAuth", auth);
    } catch (error) {
      console.log(error);
      alert("Erro ao Criar Auth --" + error.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
  async login({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    try {
      const auth = await authService.login(payload);

      commit("setAuth", auth);
    } catch (error) {
      alert("Erro ao logar: " + error.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
  setSegment({ commit }, payload) {
    commit("setSegment", payload);
  },
  setAuth({ commit }, payload) {
    commit("setAuth", payload);
  },
  async signOut({ commit, dispatch }) {
    try {
      await authService.signOut();
    } catch (e) {
      alert("Erro ao fazer logout: " + e.message);
      commit("setError", "error", { root: true });
      dispatch("loading", false, { root: true });
    }
  },
  set({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });

    commit("setAuth", payload);

    dispatch("loading", false, { root: true });
  },
};

export default actions;
