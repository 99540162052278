<script>
export default {
  name: "AppProgressCircular",
};
</script>

<template>
  <div class="loading-container">
    <v-progress-circular
      indeterminate
      color="#006838"
      size="28"
      :width="3"
    ></v-progress-circular>
  </div>
</template>

<style lang="scss" scoped></style>
