import candidateOrder from "@/constants/candidate-order";
import candidateFilter from "@/constants/candidate-filter";

const initialState = () => ({
  selectionList: undefined,
  order: candidateOrder.find((e) => e.value === "createdAt"),
  filter: candidateFilter.find((e) => e.value === "all"),
});

const state = initialState();

export default state;
