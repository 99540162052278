<script>
import TheInnerToolbar from "@/components/organisms/TheInnerToolbar.vue";
import CandidateShowCard from "@/components/molecules/CandidateShowCard.vue";
import CandidateShowSibling from "@/components/molecules/CandidateShowSibling.vue";
import CandidateShowDocument from "@/components/molecules/CandidateShowDocument.vue";
import CandidateShowAddressCard from "@/components/molecules/CandidateShowAddressCard.vue";
import CandidateShowResponsible from "@/components/molecules/CandidateShowResponsible.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";

import { getSegmentLevels } from "@/helpers/get-segment-levels";

export default {
  name: "CandidateShowTemplate",
  components: {
    AppDialog,
    TheInnerToolbar,
    CandidateShowCard,
    CandidateShowSibling,
    CandidateShowDocument,
    CandidateShowResponsible,
    CandidateShowAddressCard,
  },
  data() {
    return {
      // dialog: false,
      // dialog2: false,
      dialog: {
        confirm: false,
        tempEvent: {},
      },
    };
  },
  props: {
    candidate: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    contactClick(payload) {
      this.$emit("contactClick", payload);
    },
    documentClick(payload) {
      this.$emit("documentClick", payload);
    },
    getGradeTitle(grade) {
      let gradeList = getSegmentLevels();
      return gradeList.find((gradeItem) => gradeItem.value === grade).text;
    },
    dialogOpen() {
      if (this.candidate.pdfUrl === undefined) {
        this.dialog.confirm = true;
      } else {
        this.$emit("clickPdf");
      }
    },
    dialogConfirm() {
      this.dialog.confirm = false;
      this.$emit("clickPdf");
    },
    dialogCancel() {
      this.dialog.confirm = false;
    },
  },
};
</script>

<template>
  <div class="profile-container">
    <the-inner-toolbar
      v-if="candidate.pdfUrl === undefined"
      style="margin-bottom: 32px"
      title="Perfil do Candidato"
      subtitle="Seleção 2025"
      icon="mdi-file-pdf-box"
      @click="dialogOpen"
      @cancel="cancel"
    />
    <the-inner-toolbar
      v-else-if="candidate.pdfUrl.length === 0"
      style="margin-bottom: 32px"
      title="Perfil do Candidato"
      icon="mdi-progress-clock"
      subtitle="Seleção 2025"
      :disabled="true"
      @click="dialogOpen"
      @cancel="cancel"
    />
    <the-inner-toolbar
      v-else
      style="margin-bottom: 32px"
      title="Perfil do Candidato"
      subtitle="Seleção 2025"
      icon="mdi-file-download"
      @click="dialogOpen"
      @cancel="cancel"
    />
    <span id="candidate-info-container">
      <candidate-show-card
        :id="candidate.id"
        :name="candidate.name"
        :birthday="candidate.birthday"
        :createdAt="candidate.createdAt"
        :period="candidate.school.period"
        :nextGrade="getGradeTitle(candidate.school.nextGrade)"
        :originSchool="candidate.school.originSchool"
      />
      <candidate-show-responsible
        :responsibleList="candidate.responsibles"
        @click="contactClick"
      />
      <candidate-show-document
        :documents="candidate.document"
        @click="documentClick"
      />
      <candidate-show-address-card
        v-if="candidate.address.cep.length > 0"
        :address="candidate.address"
      />
      <candidate-show-sibling
        v-if="candidate.siblings?.length > 0"
        :siblings="candidate.siblings"
      />
    </span>
    <app-dialog
      :dialog="dialog.confirm"
      title="Confirmação"
      text="Um PDF com os dados do Candidato será gerado ao confirmar. O tempo médio é de 1 a 2 minutos para ter acesso ao PDF."
      buttonCancel="Cancelar"
      buttonConfirm="Gerar PDF"
      @clickConfirm="dialogConfirm('confirm')"
      @clickCancel="dialogCancel('confirm')"
    />
  </div>
</template>

<style lang="scss" scoped>
.profile-container {
  min-height: 100vh !important;
  min-width: 100% !important;
  padding: 16px;

  @media screen and (min-width: 1200px) {
    padding-top: 5vw;
  }

  h3 {
    font-size: var(--font-big);
    text-align: center;
    margin: 0 auto;
    padding: 0 0.6em 24px 0.6em;
  }
}

#candidate-info-container {
  width: 100%;
  display: flex;
  padding-top: 24px;
  gap: 16px 32px;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    width: 50%;
    min-width: 700px;
    margin: 0 auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 48px;
    * {
      flex: 0 0 calc(50% - 16px);
    }
  }
}
</style>
