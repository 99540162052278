import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";

import vuetify from "./plugins/vuetify";
import moment from "moment";

moment.locale("pt-br");

Vue.config.productionTip = false;
Vue.use(router);
Vue.use(store);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
