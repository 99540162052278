<script>
import format from "@/helpers/format";
import schoolLevelMedio from "@/constants/segments/medio";
import schoolLevelFund1 from "@/constants/segments/fundamental1";
import schoolLevelFund2 from "@/constants/segments/fundamental2";
import schoolLevelInfantil from "@/constants/segments/infantil";

export default {
  name: "CandidateShowSibling",
  data() {
    return {
      gradeList: [],
    };
  },
  props: {
    id: { type: String },
    name: {
      type: String,
      default: "",
    },
    nextGrade: {
      type: String,
      default: "",
    },
  },
  created() {
    this.gradeList = [
      ...schoolLevelFund1,
      ...schoolLevelMedio,
      ...schoolLevelFund2,
      ...schoolLevelInfantil,
    ];
  },
  computed: {
    formatedBirthday: function () {
      return format.date(this.birthday);
    },
    formatedName: function () {
      return format.name(this.name);
    },
    nextGradeText: function () {
      return this.gradeList.find((grade) => grade.value === this.nextGrade)
        .text;
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <span class="candidate-show-sibling-item-info">
    <div class="candidate-show-sibling-item-name">
      <p>{{ name }}</p>
      <p>{{ nextGradeText }}</p>
    </div>

    <!-- generate a dialog to confirm de call / whatss / whatever -->
  </span>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-small);
}
.candidate-show-sibling-item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 36px;
  padding-right: 16px;
}
.candidate-show-sibling-item-name {
  text-align: left;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.candidate-show-sibling-item-info {
  width: 100%;

  display: flex;
  flex-direction: row;
  padding: 4px;
  justify-content: space-between;
}
</style>
