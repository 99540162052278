<script>
export default {
  name: "TheToolbar",
  props: {
    title: { type: String },
    subtitle: { type: String },
    icon: { type: String },
  },
  methods: {
    emit() {
      this.$emit("click");
    },
  },
};
</script>

<template>
  <v-toolbar id="toolbar" flat>
    <div style="width: 40px"></div>

    <v-spacer></v-spacer>

    <v-toolbar-title style="text-align: center">
      <small style="font-size: var(--font-small)">{{ subtitle }}</small>
      <h5>{{ title }}</h5>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn
      v-if="icon != undefined && icon.length > 0"
      @click="emit"
      icon
      style="border: 0 !important"
    >
      <v-icon color="#607ecc" style="text-shadow: 0px 0px 20px #0085ff">{{
        icon
      }}</v-icon>
    </v-btn>
    <div v-else style="width: 40px"></div>
  </v-toolbar>

  <!-- // TODO: Add here another toolbar option? Or 2 versions + flow of control?  -->
  <!-- desktop template -->
</template>

<style lang="scss">
#toolbar {
  height: 64px !important;
  max-height: 64px !important;
  border-radius: 20px 20px 20px 20px;
  background-color: var(--white-opacity);
  // @media screen and (min-width: 1200px) {
  //   height: 80px !important;
  //   max-height: 80px !important;
  // }
  .v-toolbar__content {
    height: 64px !important;
    max-height: 64px !important;
    border-radius: 20px 20px 20px 20px;
    padding: 4px 8px;
    @media screen and (min-width: 1200px) {
      height: 80px !important;
      justify-content: flex-start;
    }
  }
}

.v-sheet.v-toolbar {
  padding: 0 12px 0 12px;
  border: 0px;
  width: 100%;

  box-shadow: var(--box-shadow) !important;
}
</style>
