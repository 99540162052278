<script>
import CardButtons from "@/components/molecules/CardButtons.vue";
import AppImage from "@/components/atoms/AppImage.vue";
import AppCard from "@/components/atoms/AppCard.vue";
import AppForm from "@/components/atoms/AppForm.vue";
import AddressForm from "@/components/molecules/AddressForm.vue";
import SchoolForm from "@/components/molecules/SchoolForm.vue";
import SelectionSiblingsForm from "@/components/molecules/SelectionSiblingsForm.vue";
import { deepCopyObject } from "@/helpers/deepCopyObject";

export default {
  components: {
    CardButtons,
    AppImage,
    AppCard,
    AppForm,
    SchoolForm,
    AddressForm,
    SelectionSiblingsForm,
  },
  name: "ModularCard",
  data() {
    return {
      submitAction: false,
      validateAction: false,
    };
  },
  props: {
    cardModules: Object,
    hasCancel: {
      type: Boolean,
      default: true,
    },
    buttonBackDisabled: {
      type: Boolean,
      default: false,
    },
    buttonNextDisabled: {
      type: Boolean,
      default: false,
    },
    buttonBackText: { type: String },
    customStyle: {
      type: String,
      default: "min-height: calc(100% - 44px);",
    },
  },
  methods: {
    previous() {
      this.$emit("previous");
    },
    // validate() {
    //   if(this.)
    // },
    next() {
      this.validateAction = true;
    },
    validateResult(payload) {
      if (payload) this.$emit("next");
      this.validateAction = false;
    },
    submitResult(payload) {
      if (payload) this.submit();
      this.submitAction = false;
    },
    submit() {
      this.$emit("submit");
    },
    change(payload) {
      this.$emit("change", payload);
    },
    changeSiblings(payload) {
      this.$emit("changeSiblings", payload);
    },
    click() {
      this.$emit("click");
    },
    handleDefaultClick(payload) {
      console.log(payload);
    },
    submitClick() {
      this.submitAction = true;
    },
    typeEnter() {
      if (this.step < Object.keys(this.stepList).length - 1) {
        this.validateAction = true;
      } else {
        this.submitAction = true;
      }
    },
  },
  computed: {
    stepList() {
      return deepCopyObject(this.$store.state.public.selectionStepList);
    },
    step() {
      return this.$store.state.public.selectionFormStep || 1;
    },
  },
};
</script>

<template>
  <app-card
    class="card-default"
    :customStyle="customStyle + 'padding: 24px; width: 100%;'"
  >
    <!-- TODO: stepListItem . eachCardresponsability -->
    <!-- foreach module.key -> -->
    <div
      v-for="item in cardModules"
      v-bind:key="item.key"
      class="step-card__module"
    >
      <div v-if="item.type === 'title'" class="step-card-title">
        <p>{{ item.title }}</p>
      </div>
      <div v-if="item.type === 'subtitle'" class="step-card__subtitle">
        {{ item.subtitle }}
      </div>
      <span v-if="item.type === 'image'" class="step-card-image">
        <app-image :image="item.image" :customStyle="item.customStyle" />
      </span>
      <span v-if="item.type === 'form'" class="step-card-form">
        <address-form
          v-if="item.name === 'address'"
          :formName="item.name"
          :inputList="item.form"
          :validateAction="validateAction"
          @validate="validateResult"
          @change="change"
        />
        <school-form
          v-else-if="item.name === 'school'"
          :formName="item.name"
          :inputList="item.form"
          :validateAction="validateAction"
          @validate="validateResult"
          @change="change"
        />
        <selection-siblings-form
          v-else-if="item.name === 'siblings'"
          :formName="item.name"
          :inputList="item.form"
          :submitAction="submitAction"
          :validateAction="validateAction"
          @validate="validateResult"
          @submitValidate="submitResult"
          @change="changeSiblings"
          @submit="typeEnter"
        />
        <app-form
          v-else
          :formName="item.name"
          :inputList="item.form"
          :submitAction="submitAction"
          :validateAction="validateAction"
          @validate="validateResult"
          @submitValidate="submitResult"
          @change="change"
          @submit="typeEnter"
        />
      </span>
      <div class="step-card-divider"></div>
      <span v-if="item.type === 'buttons'" class="step-card__buttons">
        <card-buttons
          :buttons="item.buttons"
          :buttonBackDisabled="buttonBackDisabled"
          :buttonNextDisabled="buttonNextDisabled"
          :buttonBackText="buttonBackText"
          @previous="previous"
          @next="next"
          @submit="submitClick"
          @click="click"
        />
        <slot />
      </span>
    </div>
  </app-card>
</template>

<style lang="scss">
/* .step-card-content {
  display: flex;
  padding: 18px 16px;
  flex-direction: column;
  justify-content: flex-start;
  gap: 26px;
  color: var(--gray-darker);
} */
.step-card__module:nth-last-child(2) {
  flex-grow: 1;
}
.step-card__buttons {
  margin-bottom: auto;
}

.card-default {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 18px;
  color: var(--gray-darker);
  max-width: 448px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.7) !important;
}
.step-card-title {
  font-family: "Quicksand";
  font-size: var(--font-title-big) !important;
  font-weight: 600;
  color: var(--gray-darker);

  @media screen and (min-width: 700px) {
    font-size: 2.2em !important;
  }
}
.step-card__subtitle {
  font-weight: 400;
  font-size: var(--font-bigger);
  line-height: 124%;

  @media screen and (min-width: 410px) {
    font-size: 20px !important;
  }
  @media screen and (min-width: 700px) {
    font-size: 24px !important;
  }
}
.step-card-image {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
