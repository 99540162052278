import router from "../router";
import modules from "./modules";

const actions = {
  logout({ commit, dispatch }) {
    Object.keys(modules).forEach((moduleName) => {
      commit(`${moduleName}/RESET`);
    });
    dispatch("auth/signOut");
    setTimeout(
      () => window.localStorage.removeItem("selecao.literato.net"),
      300
    );
    dispatch("loading", false);
    if (router.currentRoute.path != "/admin/login") router.push("/admin/login");
  },
  loading({ commit }, payload) {
    commit("setLoading", payload);
  },
  signOutAction() {},
};

export default actions;
