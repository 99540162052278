<script>
import CandidateListCard from "../molecules/CandidateListCard.vue";
import AppList from "@/components/atoms/AppList.vue";

import turns from "@/constants/turns";
import { getSegmentLevels } from "@/helpers/get-segment-levels";

export default {
  components: {
    CandidateListCard,
    AppList,
  },
  name: "CandidateList",
  props: {
    candidateList: {
      type: Array,
      default: () => {
        [];
      },
    },
    currentSorting: {
      type: String,
      default: "createdAt",
    },
    dateFilter: {
      type: String || null,
    },
    segmentFilter: {
      type: String,
      default: "all",
    },
  },
  methods: {
    clickPdf(event) {
      this.$emit("clickPdf", event);
    },
    clickProfile(event) {
      this.$emit("clickProfile", event);
    }, // Local Methods
    sortByName(list) {
      return list ? list.sort((a, b) => a.name.localeCompare(b.name)) : [];
    },
    sortByCreatedAt(list) {
      return list ? list.sort((a, b) => b.createdAt - a.createdAt) : [];
    },
    getGradeTitle(grade) {
      let gradeList = getSegmentLevels();
      return gradeList.find((gradeItem) => gradeItem.value === grade).text;
    },
    getPeriodTitle(period) {
      return turns.find((periodItem) => periodItem.value === period).text;
    },
  },
  computed: {
    currentCandidates: function () {
      switch (this.currentSorting) {
        case "name":
          return this.sortByName(this.filteredCandidates);
        case "createdAt":
          return this.sortByCreatedAt(this.filteredCandidates);
        default:
          return [];
      }
    },
    filteredCandidates: function () {
      let filteredList = this.candidateList.map((a) => {
        return { ...a };
      });
      if (this.dateFilter != null) {
        filteredList = filteredList.filter(
          (candidate) =>
            new Date(candidate.createdAt.seconds * 1000)
              .toISOString()
              .split("T")[0] == this.dateFilter
        );
      }
      if (this.segmentFilter != "all") {
        filteredList = filteredList.filter(
          (candidate) => candidate.school.nextGrade === this.segmentFilter
        );
      }
      return filteredList;
    },
  },
};
</script>

<template>
  <section id="candidate-list-container" v-if="candidateList != undefined">
    <app-list
      v-if="candidateList.length > 0"
      id="candidate-list"
      customStyle="padding-bottom: 96px !important;  padding-top: 0;display: flex; flex-direction: column"
    >
      <candidate-list-card
        v-for="candidate in currentCandidates"
        v-bind:key="candidate.id"
        :id="candidate.id"
        :name="candidate.name"
        :period="getPeriodTitle(candidate.school.period)"
        :createdAt="candidate.createdAt"
        :originSchool="candidate.school.originSchool"
        :nextGrade="getGradeTitle(candidate.school.nextGrade)"
        :birthday="candidate.birthday"
        :pdfUrl="candidate.pdfUrl"
        @clickPdf="clickPdf"
        @clickProfile="clickProfile"
      ></candidate-list-card>
    </app-list>
  </section>
</template>

<style lang="scss">
#candidate-list {
  gap: 4px 16px !important;
}
section {
  overflow: visible;
  width: 100%;
}
.v-text-field.v-text-field--solo .v-input__control {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.v-btn--fixed.v-btn--bottom {
  bottom: calc(64px + 16px);
}

.toolbar-container {
  padding: 40px 5vw;
}

#candidate-list-container {
  .v-list-item {
    @media screen and (min-width: 768px) {
      flex: 1 1 400px;
      max-width: max(calc(50% - 16px), 400px);
    }
    @media screen and (min-width: 1200px) {
      flex: 1 1 calc((100% - 32px) / 3);
      max-width: calc((100% - 32px) / 3);
    }
  }
}
</style>
