// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/bg_mobile.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".loading-bg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;height:100%;width:100%}.loading-bg .v-overlay__scrim{background-color:transparent!important}.loading-bg .v-overlay__content{color:var(--gray-darker)!important}.loading-template{transform:translateY(-8%);height:100%;width:100%;display:flex;justify-content:center;flex-direction:column;align-items:center;gap:32px}.loading-template h4{font-weight:500}", ""]);
// Exports
module.exports = exports;
