<script>
import CandidateShowTemplate from "@/components/templates/admin/CandidateShowTemplate.vue";
import { convertSelectionCandidateToPdf } from "@/functions/selection/convertSelectionCandidateToPdf";

export default {
  components: { CandidateShowTemplate },
  name: "CandidateShowPage",
  props: {
    id: { type: String },
  },
  computed: {
    candidate: function () {
      let cand = {};
      if (this.$store?.state?.selection?.selectionList) {
        cand = this.$store?.state?.selection?.selectionList.find(
          (candidate) => candidate.id === this.id
        );
        if (cand === undefined) {
          if (this.$store?.state?.contest?.contestList) {
            cand = this.$store?.state?.contest?.contestList.find(
              (candidate) => candidate.id === this.id
            );
          }
        }
        return cand;
      } else {
        return undefined;
      }
    },
  },
  updated() {
    if (this.candidate === undefined) {
      this.$store.dispatch("selection/list", { root: true });
    }
  },
  mounted() {
    if (this.candidate === undefined) {
      this.$store.dispatch("selection/list", { root: true });
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    handleContactClick(payload) {
      switch (payload.type) {
        case "whatsapp":
          window.open(
            "https://wa.me/" + this.cleanNumber(payload.data),
            "_blank" // <- This is what makes it open in a new window.
          );
          break;
        case "email":
          location.href = `mailto:${payload.data}`;
          break;
        case "phone":
          location.href = "tel:" + this.cleanNumber(payload.data);
          break;
      }
    },
    async clickPdf() {
      if (this.candidate.pdfUrl === undefined) {
        let newPayload = {
          id: this.id,
          candidate: convertSelectionCandidateToPdf(this.candidate),
        };
        this.$store.dispatch("loading", true);
        await this.$store.dispatch("selection/update", newPayload);
      } else if (this.candidate.pdfUrl.length > 0) {
        window.open(
          this.candidate.pdfUrl,
          "_blank" // <- This is what makes it open in a new window.
        );
      }
    },
    handleDocumentClick(payload) {
      switch (payload.type) {
        case "view":
          window.open(
            payload.data,
            "_blank" // <- This is what makes it open in a new window.
          );
          break;
        case "download":
          location.href = payload.data;
          break;
      }
    },
    cleanNumber(number) {
      return number.replace(/[- )(]/g, "");
    },
  },
};
</script>

<template>
  <candidate-show-template
    v-if="candidate"
    :candidate="candidate"
    @cancel="cancel"
    @clickPdf="clickPdf"
    @contactClick="handleContactClick"
    @documentClick="handleDocumentClick"
  />
</template>
