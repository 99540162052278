// import selectionService from "../../services/selectionService";

const actions = {
  // async createCandidate({ dispatch }, payload) {
  //   dispatch("loading", true, { root: true });
  //   try {
  //     return await selectionService.update(payload);
  //   } catch (err) {
  //     console.log(err);
  //     alert("Erro ao Criar Acesso --" + err.message);
  //   }
  //   dispatch("loading", false, { root: true });
  // },
  reset({ commit }) {
    commit("RESET");
  },
  setSelectionStepList({ commit }, payload) {
    commit("setSelectionStepList", payload);
  },
  setSelectionFormStep({ commit }, payload) {
    commit("setSelectionFormStep", payload);
  },
  setSelectionImageData({ commit }, payload) {
    commit("setSelectionImageData", payload);
  },
  setContestStepList({ commit }, payload) {
    commit("setContestStepList", payload);
  },
  setContestFormStep({ commit }, payload) {
    commit("setContestFormStep", payload);
  },
  setContestImageData({ commit }, payload) {
    commit("setContestImageData", payload);
  },
};

export default actions;
