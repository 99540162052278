import { deepCopyObject } from "@/helpers/deepCopyObject";
import selectionCreateForm from "@/constants/form/selection-create-form";
import contestCreateForm from "@/constants/form/contest-create-form";

const initialState = () => ({
  selectionStepList: deepCopyObject(selectionCreateForm),
  selectionFormStep: 1,
  selectionImageData: {
    identity: undefined,
    grade: undefined,
  },
  contestStepList: deepCopyObject(contestCreateForm),
  contestFormStep: 1,
  contestImageData: {
    identityFront: undefined,
    identityBack: undefined,
    grade: undefined,
    enem: undefined,
  },
});

const state = initialState();

export default state;
