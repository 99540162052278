<script>
export default {
  name: "AppIcon",
  data() {
    return {};
  },
  props: {
    color: { type: String },
    size: { type: String },
    icon: { type: String },
    customStyle: { type: String },
  },
};
</script>

<template>
  <v-icon :color="color" :size="size" :style="customStyle">{{ icon }}</v-icon>
</template>

<style module></style>
