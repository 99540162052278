import initialState from "./state";

const mutations = {
  RESET: (state) => {
    Object.assign(state, initialState);
  },
  setSelectionStepList: (state, payload) => {
    state.selectionStepList = payload;
    return state;
  },
  setSelectionFormStep: (state, payload) => {
    state.selectionFormStep = payload;
    return state;
  },
  setSelectionImageData: (state, payload) => {
    state.selectionImageData = payload;
    return state;
  },
  setContestStepList: (state, payload) => {
    state.contestStepList = payload;
    return state;
  },
  setContestFormStep: (state, payload) => {
    state.contestFormStep = payload;
    return state;
  },
  setContestImageData: (state, payload) => {
    state.contestImageData = payload;
    return state;
  },
};

export default mutations;
