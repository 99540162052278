const schoolLevelFund2 = [
  {
    value: "year6",
    text: "6º Ano",
  },
  {
    value: "year7",
    text: "7º Ano",
  },
  {
    value: "year8",
    text: "8º Ano",
  },
  {
    value: "year9",
    text: "9º Ano",
  },
];

export default schoolLevelFund2;
