const schoolLevelFund1 = [
  {
    value: "year2",
    text: "2º Ano",
  },
  {
    value: "year3",
    text: "3º Ano",
  },
  {
    value: "year4",
    text: "4º Ano",
  },
  {
    value: "year5",
    text: "5º Ano",
  },
];

export default schoolLevelFund1;
