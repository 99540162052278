<script>
import AppCard from "@/components/atoms/AppCard.vue";
import CandidateShowResponsibleItem from "@/components/molecules/CandidateShowResponsibleItem.vue";

export default {
  name: "CandidateShowResponsible",
  components: {
    AppCard,
    CandidateShowResponsibleItem,
  },
  data() {
    return {
      responsibles: [],
    };
  },
  mounted() {
    this.responsibles.push(this.responsibleList[0]);
    if (this.responsibles.length > 1) {
      if (this.responsibles[0].cpf != this.responsibleList[1].cpf) {
        this.responsibles.push(this.responsibleList[1]);
      }
    }
  },
  props: {
    id: { type: String },
    responsibleList: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <app-card
    class=""
    customStyle="padding: 16px; width: 100%;background-color: var(--white-opacity);"
  >
    <span class="candidate-show-responsible-info">
      <div class="candidate-show-responsible-name">
        <p>Responsáveis</p>
      </div>
      <span
        v-for="responsible in responsibles"
        v-bind:key="responsible.cpf"
        style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <candidate-show-responsible-item
          :name="responsible.name"
          :cpf="responsible.cpf"
          :birthday="responsible.birthday"
          :phone="responsible.phone"
          :email="responsible.email"
          @click="emit"
        />
        <div
          v-if="Object.keys(responsibles).length > 1"
          class="divider"
          style="margin-top: 0"
        />
      </span>
      <!-- generate a dialog to confirm de call / whatss / whatever -->
    </span>
  </app-card>
</template>

<style lang="scss" scoped>
p {
  margin: 2px 0 !important;
  font-weight: 600;
  font-size: var(--font-bigger);
}
.candidate-show-responsible-name {
  text-align: left;
}

.candidate-show-responsible-info {
  margin: 0.5vw 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
