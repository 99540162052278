import schoolLevelInfantil from "@/constants/segments/infantil";
import schoolLevelFund1 from "@/constants/segments/fundamental1";
import schoolLevelFund2 from "@/constants/segments/fundamental2";
import schoolLevelMedio from "@/constants/segments/medio";

export function getSegmentLevels(segment) {
  switch (segment) {
    case "infantil": {
      return schoolLevelInfantil;
    }
    case "fundamental1": {
      return schoolLevelFund1;
    }
    case "fundamental2": {
      return schoolLevelFund2;
    }
    case "medio": {
      return schoolLevelMedio;
    }
    default: {
      return [
        ...schoolLevelInfantil,
        ...schoolLevelFund1,
        ...schoolLevelFund2,
        ...schoolLevelMedio,
      ];
    }
  }
}
