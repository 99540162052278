import firebase from "@/firebase";

const contestService = {
  async create(newContest) {
    const response = await firebase
      .firestore()
      .collection("contest2025")
      .add(newContest);

    if (response) {
      return response.id;
    } else {
      return "";
    }
  },

  async set(payload) {
    let response = await firebase
      .firestore()
      .collection("contest2025")
      .doc(payload.id)
      .set({ ...payload.contest });
    if (response) {
      return true;
    } else {
      return false;
    }
  },

  async list() {
    const response = await firebase.firestore().collection("contest2025").get();

    if (response) {
      let contestList = [];
      response.forEach((doc) => {
        let contest = doc.data();
        contest.id = doc.id;
        contestList.push(contest);
      });
      return contestList;
    } else {
      return [];
    }
  },
};

export default contestService;
