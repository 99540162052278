<script>
import AppProgressCircular from "@/components/atoms/AppProgressCircular.vue";
import AppImage from "@/components/atoms/AppImage.vue";

export default {
  name: "AppLoadingTemplate",
  components: { AppProgressCircular, AppImage },

  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <div>
    <v-overlay class="loading-bg">
      <span class="loading-template">
        <app-image
          image="logo"
          customStyle="transform: translateX(-6px); max-width: 90%; width: 90%;"
          height="12%"
        />
        <app-progress-circular />
        <h4><span style="color: #006838">Carregando...</span></h4>
        <h4><span style="color: #006838">Não feche</span> esta tela.</h4>
      </span>
    </v-overlay>
  </div>
</template>

<style lang="scss">
.loading-bg {
  background-image: url("../../assets/bg_mobile.png");
  background-size: cover;
  height: 100%;
  width: 100%;

  .v-overlay__scrim {
    background-color: transparent !important;
  }

  .v-overlay__content {
    color: var(--gray-darker) !important;
  }
}
.loading-template {
  transform: translateY(-8%);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  h4 {
    font-weight: 500;
  }
}
</style>
