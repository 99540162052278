<script>
export default {
  name: "ContestSchoolInfo",
  props: {
    originSchool: {
      type: String,
      default: "--",
    },
  },
};
</script>

<template>
  <div class="contest-school-info">
    <span class="contest-school-info__row">
      <p class="contest-school-info__title">
        <small>
          <strong>Escola de Origem: </strong>
        </small>
      </p>
      <p class="contest-school-info__data">
        <small>
          {{ originSchool }}
        </small>
      </p>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.contest-school-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  // flex-direction: column;
  justify-content: center;

  p {
    margin: 0px !important;
  }

  .contest-school-info__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;

    .contest-school-info__title {
      width: calc(48% - 6px);
    }

    .contest-school-info__data {
      width: calc(52% - 6px);
    }
    p {
      margin: 2px 0 !important;
      font-weight: 600;
      font-size: var(--font-medium);

      small {
        font-size: 13px;
        font-weight: 500;
        color: var(--gray-dark);
      }
    }
    @media screen and (min-width: 400px) {
      gap: 12px;
      p {
        small {
          font-size: var(--font-smaller);
        }
      }
    }
  }

  /* @media screen and (min-width: 1200px) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    } */
}
</style>
