<script>
import AppInput from "@/components/atoms/AppInput.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import validationRules from "@/constants/form/validation-rules";
import segmentList from "@/constants/segment-list";

export default {
  name: "SelectionSiblingsForm",
  components: {
    AppInput,
    AppButton,
  },
  data() {
    return {
      form: {},
      valid: true,
      count: 1,
      validationRules,
      hasSiblings: false,
      siblings: [],
      segmentList,
      segmentLevels: [
        {
          value: "maternal1",
          text: "Infantil - Maternal 1",
        },
        {
          value: "maternal2",
          text: "Infantil - Maternal 2",
        },
        {
          value: "infant1",
          text: "Infantil - Infantil 1",
        },
        {
          value: "infant2",
          text: "Infantil - Infantil 2",
        },
        {
          value: "year1",
          text: "Infantil - 1º Ano",
        },
        {
          value: "year2",
          text: "Fund. Anos Iniciais - 2º Ano",
        },
        {
          value: "year3",
          text: "Fund. Anos Iniciais - 3º Ano",
        },
        {
          value: "year4",
          text: "Fund. Anos Iniciais - 4º Ano",
        },
        {
          value: "year5",
          text: "Fund. Anos Iniciais - 5º Ano",
        },
        {
          value: "year6",
          text: "Fund. Anos Finais - 6º Ano",
        },
        {
          value: "year7",
          text: "Fund. Anos Finais - 7º Ano",
        },
        {
          value: "year8",
          text: "Fund. Anos Finais - 8º Ano",
        },
        {
          value: "year9",
          text: "Fund. Anos Finais - 9º Ano",
        },
        {
          value: "medio1",
          text: "Ensino Médio - 1ª Série",
        },
        {
          value: "medio2",
          text: "Ensino Médio - 2ª Série",
        },
        {
          value: "medio3",
          text: "Ensino Médio - 3ª Série",
        },
      ],
    };
  },
  mounted() {
    for (var input in this.inputList) {
      if (input != undefined) {
        this.form[input] = this.inputList[input].value
          ? this.inputList[input].value
          : "";
      }
    }

    this.emit({ name: "change", formName: this.formName, value: this.form });
  },
  props: {
    formName: { type: String },
    submitAction: { type: Boolean },
    validateAction: { type: Boolean },
    /* still to make validation object */
    inputList: { type: Object },
  },
  watch: {
    inputList: {
      handler(val) {
        Object.keys(val).forEach((key) => {
          this.form[key] = val[key].value;
        });
        /* this.updateValue({ name: name, value: value }); */
      },
      deep: true,
      immediate: true,
    },
    submitAction(val) {
      if (val == true) {
        this.validateSubmit();
      }
    },
    validateAction(val) {
      if (val == true) {
        this.validate();
      }
    },
  },
  methods: {
    handleChange({ name, value }) {
      this.updateValue({ name: "siblingsKeys", value: this.siblings });
      this.updateValue({ name: name, value: value });
      this.emit({
        name: "change",
        formName: this.formName,
        value: this.form,
      });
    },

    validate() {
      this.$emit("validate", this.$refs["form"].validate());
    },
    updateValue({ name, value }) {
      this.form[name] = value;
    },
    validateSubmit() {
      this.$emit("submitValidate", this.$refs["form"].validate());
    },
    emit({ name, formName, value }) {
      this.$emit(name, { name: formName, fields: value });
    },
    changeHasSiblings() {
      this.hasSiblings = !this.hasSiblings;
      if (this.hasSiblings === true && this.siblings.length === 0) {
        this.count = 2;
        this.siblings = [
          {
            key: 1,
          },
        ];
      }
    },
    addSibling() {
      let sibling = {
        key: this.count,
      };
      this.count++;
      this.siblings.push(sibling);
    },
    removeSibling(sibling) {
      let index = this.siblings.indexOf(sibling);

      if (index >= 0) {
        this.siblings.splice(index, 1);
      }
    },
  },
};
</script>

<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="align-center justify-center"
    style="width: 100%; max-width: 448px"
  >
    <app-input
      :value="hasSiblings"
      label="Você possui outros filhos? *"
      name="hasSiblings"
      inputType="switch"
      @change="changeHasSiblings"
    />

    <div v-if="hasSiblings" class="form__inputs">
      <span
        v-for="siblingItem in siblings"
        v-bind:key="siblingItem.key"
        class="sibling-form"
      >
        <div class="divider" />
        <app-input
          label="Nome do filho"
          :name="'name' + siblingItem.key"
          :validationRules="
            validationRules['name'] ? [validationRules['name']] : []
          "
          @change="handleChange"
          @enter="validateSubmit" />
        <app-input
          label="Série em 2025 do filho"
          :name="'nextGrade' + siblingItem.key"
          inputType="select"
          :items="segmentLevels"
          :validationRules="
            validationRules['nextGrade'] ? [validationRules['nextGrade']] : []
          "
          @change="handleChange"
          @enter="validateSubmit" />
        <app-button
          name="add-sibling"
          text="Remover Filho"
          type="new-return"
          icon="mdi-plus"
          @click="removeSibling(siblingItem)"
      /></span>

      <app-button
        name="add-sibling"
        text="Adicionar Filho"
        type="new-default"
        icon="mdi-plus"
        @click="addSibling"
      />
      <div class="divider" style="margin-top: none" />
    </div>
    <slot />
  </v-form>
</template>

<style lang="scss">
.divider {
  height: 1px;
  margin-top: 12px;
  width: 100%;
  background-color: var(--gray-dark);
}
.form__inputs {
  .sibling-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
