import turns from "@/constants/turns";
import schoolLevelMedio from "@/constants/segments/medio";
import schoolLevelFund1 from "@/constants/segments/fundamental1";
import schoolLevelFund2 from "@/constants/segments/fundamental2";
import schoolLevelInfantil from "@/constants/segments/infantil";

export function convertSelectionCandidateToPdf(payload) {
  let gradeList = [
    ...schoolLevelFund1,
    ...schoolLevelMedio,
    ...schoolLevelFund2,
    ...schoolLevelInfantil,
  ];

  let updatedCandidate = payload;
  updatedCandidate.pdfUrl = "";
  delete updatedCandidate.id;
  if (
    updatedCandidate.school.nextGradeText === undefined ||
    updatedCandidate.school.periodText === undefined
  ) {
    updatedCandidate.school.nextGradeText = gradeList.find(
      (grade) => grade.value === updatedCandidate.school.nextGrade
    ).text;
    updatedCandidate.school.periodText = turns.find(
      (period) => period.value === updatedCandidate.school.period
    ).text;

    if (updatedCandidate.siblings.length > 0) {
      updatedCandidate.siblings.forEach((sibling) => {
        sibling.nextGradeText = gradeList.find(
          (grade) => grade.value === sibling.nextGrade
        ).text;
      });
    }
  }

  return updatedCandidate;
}
