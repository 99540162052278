const getters = {
  selectionList: (state) => {
    return state.selectionList;
  },
  currentSorting: (state) => {
    return state.order.value;
  },
  currentFiltering: (state) => {
    return state.filter.value;
  },
};

export default getters;
