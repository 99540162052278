<script>
import AppInput from "@/components/atoms/AppInput.vue";
import validationRules from "@/constants/form/validation-rules";

export default {
  name: "SchoolForm",
  components: {
    AppInput,
  },
  data() {
    return {
      form: {},
      valid: true,
      validationRules,
    };
  },
  mounted() {
    for (var input in this.inputList) {
      if (input != undefined) {
        this.form[input] = this.inputList[input].value
          ? this.inputList[input].value
          : "";
      }
    }

    this.emit({ name: "change", formName: this.formName, value: this.form });
  },
  props: {
    formName: { type: String },
    submitAction: { type: Boolean },
    validateAction: { type: Boolean },
    /* still to make validation object */
    inputList: { type: Object },
  },
  watch: {
    inputList: {
      handler(val) {
        Object.keys(val).forEach((key) => {
          this.form[key] = val[key].value;
        });
        /* this.updateValue({ name: name, value: value }); */
      },
      deep: true,
      immediate: true,
    },
    submitAction() {
      this.submit();
    },
    validateAction(val) {
      if (val == true) {
        this.validate();
      }
    },
  },
  methods: {
    handleChange({ name, value }) {
      this.updateValue({ name: name, value: value });
      this.emit({
        name: "change",
        formName: this.formName,
        value: this.form,
      });
    },
    validate() {
      this.$emit("validate", this.$refs["form"].validate());
    },
    updateValue({ name, value }) {
      this.form[name] = value;
    },
    submit() {
      if (this.$refs["form"].validate()) {
        this.emit({
          name: "submit",
          formName: this.formName,
          value: this.form,
        });
      }
    },
    emit({ name, formName, value }) {
      this.$emit(name, { name: formName, fields: value });
    },
  },
  computed: {
    isMaternal1() {
      return (
        this.$store.state.public.selectionStepList[1][2].form.nextGrade
          .value === "maternal1"
      );
    },
  },
};
</script>

<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="align-center justify-center"
    style="width: 100%; max-width: 448px"
  >
    <div class="form__inputs">
      <span v-for="input in inputList" v-bind:key="input.name">
        <app-input
          v-if="input.name != 'originSchool'"
          :value="input.value"
          :disabled="input.disabled"
          :required="input.required"
          :keyboardType="input.keyboardType"
          :label="input.label"
          :name="input.name"
          :mask="input.mask"
          :inputType="input.inputType"
          :dateType="input.dateType"
          :appendIcon="input.appendIcon"
          :items="input.items"
          :validationRules="
            validationRules[input.name] ? [validationRules[input.name]] : []
          "
          @change="handleChange"
          @enter="submit"
        />
        <app-input
          v-else
          v-bind:key="input.name"
          :value="input.value"
          :disabled="input.disabled"
          :required="input.required"
          :keyboardType="input.keyboardType"
          :label="isMaternal1 ? 'Escola de Origem' : input.label"
          :name="input.name"
          :mask="input.mask"
          :inputType="input.inputType"
          :dateType="input.dateType"
          :appendIcon="input.appendIcon"
          :items="input.items"
          :validationRules="
            validationRules[input.name] && !isMaternal1
              ? [validationRules[input.name]]
              : []
          "
          @change="handleChange"
          @enter="submit"
        />
      </span>
    </div>
    <slot />
  </v-form>
</template>
