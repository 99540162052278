import { render, staticRenderFns } from "./CandidateShowResponsibleItem.vue?vue&type=template&id=502bb091&scoped=true&"
import script from "./CandidateShowResponsibleItem.vue?vue&type=script&lang=js&"
export * from "./CandidateShowResponsibleItem.vue?vue&type=script&lang=js&"
import style0 from "./CandidateShowResponsibleItem.vue?vue&type=style&index=0&id=502bb091&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502bb091",
  null
  
)

export default component.exports