// import firebase from "@/firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import generateRandomString from "./generateRandomString";

export async function uploadImage(imageData, folderName) {
  const storage = getStorage();
  let key = generateRandomString(28);
  const storageRef = ref(storage, folderName + key);

  let upload = await uploadBytes(storageRef, imageData).then((snapshot) => {
    return snapshot;
  });
  let downloadUrl = await getDownloadURL(upload.ref).then((downloadURL) => {
    return downloadURL;
  });
  return downloadUrl;
}
