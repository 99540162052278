import moment from "moment";

const format = {
  date: (date) => {
    return moment(date).format("L");
  },
  time: (date) => {
    return moment(date).format("HH:mm");
  },
  revertDate: (date) => {
    var dateParts = date.split("/");

    // month is 0-based, that's why we need dataParts[1] - 1
    var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

    let originalDate = moment(dateObject).format("YYYY-MM-DD");
    return originalDate;
  },
  reference: (reference) => {
    let referenceFormatted = reference
      ? moment(reference).format("MMMM, yyyy")
      : "";
    if (referenceFormatted.length > 0) {
      referenceFormatted =
        referenceFormatted.charAt(0).toUpperCase() +
        referenceFormatted.substring(1);
    }
    return referenceFormatted;
  },
  name: (name) => {
    let formattedName = name.toLowerCase().split(" ");
    for (let i = 0; i < formattedName.length; i++) {
      formattedName[i] =
        formattedName[i].charAt(0).toUpperCase() +
        formattedName[i].substring(1);
    }
    return formattedName.join(" ");
  },
};

export default format;
