<script>
export default {
  name: "AdminRouter",
  mounted() {
    if (this.$store.state.loading) {
      this.$store.dispatch("loading", false, { root: true });
    }
  },
};
</script>

<template>
  <router-view />
</template>

<style lang="scss"></style>
