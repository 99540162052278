<script>
export default {
  name: "SegmentButton",
  props: {
    text: {
      type: String,
      default: "",
    },
    gradient: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    customStyle: {
      type: String,
      default: "",
    },
  },
  methods: {
    emit() {
      this.$emit("click");
    },
  },
};
</script>

<template>
  <v-btn
    @click="emit()"
    height="62"
    depressed
    :disabled="disabled"
    :class="'btn-default btn-segment ' + customClass"
    :style="
      'background: var(' +
      gradient +
      ') !important; height: 62px !important; width: 100%; font-size: 22px !important; font-weight: 700 !important; border-radius: 20px !important;' +
      customStyle
    "
  >
    <span class="text-container"> {{ text }}</span>
    <v-icon size="40" right> mdi-chevron-right </v-icon>
  </v-btn>
</template>

<style lang="scss">
.btn-segment .btn-segments {
  @media screen and (min-width: 700px) {
    width: calc(50% - 32px) !important;
    max-width: 369px;
  }
}
.btn-segment {
  padding: 0 18px !important;
  border: 2px solid rgba(255, 255, 255, 0.75);

  @media screen and (min-height: 800px) {
    padding: 8px 24px;
    gap: 40px;
  }
  .v-btn__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
    width: 90%;
    margin: 0 auto;

    .text-container {
      min-width: 212px;
      width: 70%;
      align-items: space-between;
      font-weight: 700;
      white-space: pre-line;
      text-align: left;
    }
  }

  .v-btn__content .v-icon {
    margin: 0 !important;
  }
}
</style>
