import turns from "@/constants/turns";
import schoolLevelMedio from "@/constants/segments/medio";
import schoolLevelFund1 from "@/constants/segments/fundamental1";
import schoolLevelFund2 from "@/constants/segments/fundamental2";
import schoolLevelInfantil from "@/constants/segments/infantil";

export function convertCreateFormToSelection(payload) {
  let gradeList = [
    ...schoolLevelFund1,
    ...schoolLevelMedio,
    ...schoolLevelFund2,
    ...schoolLevelInfantil,
  ];

  let newCandidate = {
    createdAt: new Date(),
    name: payload.candidate.name,
    birthday: payload.candidate.birthday,
    phone: payload.candidate.phone,
    pdfUrl: "",
    responsibles: [
      {
        name: payload.responsible.name1,
        cpf: payload.responsible.cpf1,
        birthday: payload.responsible.birthday1,
        phone: payload.responsible.phone1,
        email: payload.responsible.email1,
      },
    ],
    school: {
      nextGrade: payload.school.nextGrade,
      nextGradeText: gradeList.find(
        (grade) => grade.value === payload.school.nextGrade
      ).text,
      period: payload.school.period,
      periodText: turns.find((period) => period.value === payload.school.period)
        .text,
      originSchool: payload.school.originSchool,
      segment: payload.school.segment,
    },
    address: {
      street: payload.address.street,
      complement: payload.address.complement,
      number: payload.address.number,
      neighborhood: payload.address.neighborhood,
      cep: payload.address.cep,
      city: payload.address.city,
      state: payload.address.state,
    },
    document: {
      identityUrl: payload.document.identityUrl || null,
      gradeUrl: payload.document.gradeUrl || null,
    },
    siblings: [],
  };

  if (
    payload.responsible.name2.length > 0 ||
    payload.responsible.cpf2.length > 0 ||
    payload.responsible.birthday2.length > 0 ||
    payload.responsible.phone2.length > 0 ||
    payload.responsible.email2.length > 0
  ) {
    let responsible2 = {
      name: payload.responsible.name2,
      cpf: payload.responsible.cpf2,
      birthday: payload.responsible.birthday2,
      phone: payload.responsible.phone2,
      email: payload.responsible.email2,
    };

    if (newCandidate.responsibles[0].cpf != responsible2.cpf) {
      newCandidate.responsibles.push(responsible2);
    }
  }

  let siblingsArray = [];

  if (
    payload.siblingsInfo != undefined &&
    Object.keys(payload.siblingsInfo).length > 0
  ) {
    let siblingsAmount = Object.keys(payload.siblingsInfo.siblingsKeys).length;

    if (siblingsAmount > 0) {
      for (let i = 0; i < siblingsAmount; i++) {
        let sibling = {
          name: payload.siblingsInfo[
            "name" + payload.siblingsInfo.siblingsKeys[i].key
          ],
          nextGrade:
            payload.siblingsInfo[
              "nextGrade" + payload.siblingsInfo.siblingsKeys[i].key
            ],

          nextGradeText: gradeList.find(
            (grade) =>
              grade.value ===
              payload.siblingsInfo[
                "nextGrade" + payload.siblingsInfo.siblingsKeys[i].key
              ]
          ).text,
        };

        siblingsArray.push(sibling);
      }
      if (siblingsArray.length > 0) {
        newCandidate.siblings = siblingsArray;
      }
    }
  }

  let birthday = newCandidate.birthday;
  let ageDifMs = Date.now() - Date.parse(birthday);
  let ageDate = new Date(ageDifMs); // miliseconds from epoch
  newCandidate.age = Math.abs(ageDate.getUTCFullYear() - 1970);

  return newCandidate;
}
