<script>
import AppIcon from "@/components/atoms/AppIcon.vue";
import AppButton from "@/components/atoms/AppButton.vue";

export default {
  name: "AppMenu",
  components: {
    AppIcon,
    AppButton,
  },
  data() {
    return {};
  },
  props: {
    title: { type: String },
    buttonType: {
      type: String,
      default: "default",
    },
    icon: { type: String },
    color: { type: String },
    textColor: { type: String },
    customStyle: { type: String },
    customClass: {
      type: String,
      default: "",
    },
    current: { type: String },
    items: { type: Array },
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload.value);
    },
  },
};
</script>

<template>
  <div
    class="text-center"
    :style="buttonType != 'icon-only' ? 'width: 100%' : ''"
  >
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="buttonType == 'default'"
          :color="color"
          v-bind="attrs"
          v-on="on"
          depressed
          :style="
            customStyle +
            ' color: ' +
            textColor +
            '; width: 100%; border: 1px solid #D2CCCC !important; text-transform: unset !important; box-shadow: var(--box-shadow);'
          "
        >
          {{ title }}
          <app-icon
            :icon="icon"
            textColor="color"
            customStyle="margin: 4px;transform: translateX(4px);"
          />
        </v-btn>
        <v-btn
          v-if="buttonType == 'new-default'"
          :color="color"
          v-bind="attrs"
          v-on="on"
          depressed
          :style="
            customStyle +
            ' color: ' +
            textColor +
            '; text-transform: unset !important;'
          "
        >
          {{ title }}
          <app-icon
            :icon="icon"
            textColor="color"
            customStyle="margin: 4px;transform: translateX(4px);"
          />
        </v-btn>

        <v-btn
          v-if="buttonType == 'icon-only'"
          icon
          :color="color"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icon }}</v-icon>
        </v-btn>

        <app-button
          v-if="buttonType == 'new-icon'"
          type="icon"
          :bind="attrs"
          :on="on"
          size="32px"
          customStyle="max-width: 32px !important;min-width: 32px !important; border-bottom: 1px solid rgba(148, 172, 207, 0.4); box-shadow: none !important; color: rgba(148, 172, 207, 0.4)"
          borderRadius="8px 8px 8px 8px"
          color="var(--white)"
          :icon="icon"
        />
        <app-button
          v-if="buttonType == 'icon'"
          type="icon"
          :bind="attrs"
          :on="on"
          size="88px"
          :customStyle="
            customStyle +
            'max-width: 64px !important;min-width: 64px !important;'
          "
          borderRadius="5px 20px 20px 5px"
          color="var(--white)"
          :icon="icon"
        />
      </template>
      <v-list class="menu-list">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="emit(item)"
        >
          <v-list-item-title :class="current == item.value ? 'active' : ''">
            {{ item.text }}
            <app-icon
              v-if="current == item.value"
              icon="mdi-check"
              color="primary"
              customStyle="transform: translateY(-2px);"
            />
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: var(--box-shadow) !important;
  border-radius: 8px !important;

  .menu-list {
    background-color: var(--white) !important;

    .active {
      color: var(--blue-flat) !important;
    }
    .v-list-item {
      padding: 0px 16px !important;
    }
  }
}
</style>
