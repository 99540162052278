var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{style:('display: block; width: ' + _vm.width + ';' + _vm.customContainerStyle)},[(_vm.type === 'icon')?_c('v-btn',_vm._g({style:('border-radius: ' +
      _vm.borderRadius +
      '; background: var(--white-opacity);border: 1px solid rgba(148, 172, 207, 0.4) !important;box-shadow: var(--box-shadow); ' +
      _vm.customStyle),attrs:{"name":_vm.name,"color":_vm.color,"depressed":"","width":_vm.width,"height":_vm.height,"disabled":_vm.disabled},on:{"click":function($event){return _vm.emit('click')}}},_vm.on),[_vm._t("default"),_c('v-icon',{style:('text-shadow: 0px 0px 20px ' + _vm.iconShadow + '; color: ' + _vm.iconColor),attrs:{"size":"size","color":_vm.iconForeground}},[_vm._v(_vm._s(_vm.icon))])],2):_vm._e(),(_vm.type === 'default')?_c('v-btn',{style:(_vm.customStyle +
      ' width: ' +
      _vm.width +
      '; !important; border-radius: 20px; background: var(--white-opacity); box-shadow: var(--box-shadow);'),attrs:{"name":_vm.name,"color":_vm.color,"disabled":_vm.disabled,"height":"46"},on:{"click":function($event){return _vm.emit('click')}}},[_vm._v(" "+_vm._s(_vm.text)+" ")]):_vm._e(),(_vm.type === 'new-default')?_c('v-btn',{class:'btn-default ' + _vm.customClass,style:(_vm.customStyle +
      'height: 36px; width: 100%; text-transform: unset !important; font-weight: 500; border-radius: 8px;'),attrs:{"name":_vm.name,"color":_vm.color,"disabled":_vm.disabled,"height":"40","depressed":""},on:{"click":function($event){return _vm.emit('click')}}},[(_vm.icon != undefined && _vm.iconPosition === 'left')?_c('v-icon',{attrs:{"size":"32","left":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.icon != undefined && _vm.iconPosition === 'right')?_c('v-icon',{attrs:{"size":"32","right":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()],1):_vm._e(),(_vm.type === 'new-return')?_c('v-btn',{class:_vm.customClass,style:(_vm.customStyle +
      'height: 36px; letter-spacing: 0; width: 100%; text-transform: unset !important; font-size: var(--font-big); font-weight: 500; border-radius: 8px; color: #969696; background-color: var(--white); border: 1px solid #94ACCF;'),attrs:{"name":_vm.name,"color":_vm.color,"disabled":_vm.disabled,"depressed":""},on:{"click":function($event){return _vm.emit('click')}}},[(_vm.icon != undefined && _vm.iconPosition === 'left')?_c('v-icon',{attrs:{"size":"24","left":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.text)+" "),(_vm.icon != undefined && _vm.iconPosition === 'right')?_c('v-icon',{attrs:{"size":"24","right":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e()],1):_vm._e(),(_vm.type === 'new-button-with-icon')?_c('v-btn',{style:(_vm.customStyle +
      ' width: 100%; font-size: var(--font-big); font-weight: 500; border-radius: 8px; color: #94ACCF; background-color: var(--white); border: 1px solid #94ACCF;'),attrs:{"name":_vm.name,"color":_vm.color,"disabled":_vm.disabled,"height":"26","depressed":""},on:{"click":function($event){return _vm.emit('click')}}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e(),(_vm.type === 'fab')?_c('v-fab-transition',[_c('v-btn',{staticClass:"v-btn--example",attrs:{"color":_vm.color,"fab":"","large":"","dark":"","bottom":"","fixed":"","right":""},on:{"click":function($event){return _vm.emit('fabClick')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icon))])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }