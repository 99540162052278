import selectionService from "../../services/selectionService";
import router from "../../router";
import store from "../../store";

const actions = {
  order({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    commit("orderUsers", payload);

    dispatch("loading", false, { root: true });
  },
  filter({ commit, dispatch }, payload) {
    dispatch("loading", true, { root: true });
    commit("filterUsers", payload);

    dispatch("loading", false, { root: true });
  },
  // Create function should really be here?
  async create({ dispatch }, payload) {
    try {
      await selectionService.create(payload);
    } catch (err) {
      dispatch("loading", false, { root: true });
      router.push("/erro");
    }
  },
  async set({ dispatch }, payload) {
    try {
      await selectionService.set(payload);
    } catch (err) {
      alert("Erro ao Criar Candidato --" + err.message);
      dispatch("loading", false, { root: true });
    }
  },

  async list({ commit, dispatch }) {
    try {
      const selectionList = await selectionService.list(
        store.state.auth.currentSegment
      );
      commit("setSelectionList", selectionList);
      /* router.push("/"); */
    } catch (error) {
      console.log("Error getting users: ", error);
      dispatch("loading", false, { root: true });
    }
  },
};

export default actions;
